import { doc, getDoc, getFirestore, setDoc } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

import { currencies } from "../constants/currencies";
import { SessionUser } from "../types/session";
import { User } from "../types/user";


export const saveUser = async (u: User) => {
  const db = getFirestore();
  delete u.password;
  if(!u.currency) {
    u.currency = currencies[0];
  }
  return setDoc(doc(db, "UserInfo", u.id), u);
};

export const loadContacts = async (u: User): Promise<Array<SessionUser>> => {
  const db = getFirestore();
  const docRef = doc(db, "UserInfo", u.id);
  const docSnap = await getDoc(docRef);

  return docSnap ? docSnap.get('contacts') as Array<SessionUser> : [];
}

export const addContact = async (u: User, contact: SessionUser) => {
  if (u.contacts.every(u => u.id !== contact.id)) {
    u.contacts.push(contact);
    await saveUser(u);
  }
}

export const saveContact = async (u: User, contact: SessionUser) => {
  const index = u.contacts.findIndex(c => c.id === contact.id);
  if (index > -1) {
    u.contacts.splice(index, 1, contact);
  }
  await saveUser(u);
}

export const removeContact = async (u: User, id: string): Promise<void> => {
  const index = u.contacts.findIndex(c => c.id === id);
  if (index > -1) {
    u.contacts.splice(index, 1);
  }
  await saveUser(u);
}

export const loadUserInfo = async (userId: string) => {
  const db = getFirestore();
  const userRef = doc(db, "UserInfo", userId);
  const docSnap = await getDoc(userRef);

  if (!docSnap.exists()) return null;
  return docSnap.data();
};

export const uploadAvatar = (userEmail: string,  file: File) => {
  const storage = getStorage();
  const storageRef = ref(storage, `${userEmail}/avatar.jpg`);
  
  return uploadBytes(storageRef, file).then(res => getDownloadURL(res.ref));
}

export const getAvatar = async (userEmail: string) => {
  try {
    const storage = getStorage();

    const storageRef = ref(storage, `${userEmail}/avatar.jpg`);  
    return await getDownloadURL(storageRef);  
  } catch (error) {
    return '';
  }  
}