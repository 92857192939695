import { FunctionComponent, useState } from "react";
import { useFormik } from "formik";

import { Expense, ReceiptItem } from "../../types/session";
import FormTextRow from "../FormTextRow";
import { generateId } from "../../utils/idUtils";
import Popup from "../Popup";
import FormCurrencyRow from "../FormCurrencyRow";
import { formatNumber } from "../../utils/numberUtils";

interface ReceiptModeProps {
  data: Expense;
  onChange: (items: Array<ReceiptItem>) => void;
  readOnly?: boolean;
}

const ReceiptMode: FunctionComponent<ReceiptModeProps> = ({
  data,
  onChange,
  readOnly,
}) => {
  const [showDialog, setShowDialog] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const formik = useFormik<ReceiptItem>({
    initialValues: {
      id: generateId(),
      name: "",
      price: 0,
      quantity: 1,
    },
    validateOnBlur: true,
    validateOnChange: false,
    onSubmit: (values) => {
      formik.validateForm();
      if (formik.isValid) {
      }
    },
  });
  const onSaveClicked = () => {
    formik.validateForm();
    if (formik.isValid) {
      const items = [...(data.items || [])];
      const index = items.findIndex((i) => i.id === formik.values.id);
      items.splice(index, index > -1 ? 1 : 0, formik.values);
      onChange(items);
      formik.resetForm();
      setShowDialog(false);
    }
  };
  const onAddNewClicked = () => {
    setShowDialog(!showDialog);
    setIsEditMode(false);
    if (!showDialog) {
      formik.values.id = generateId();
    }
  };
  const onCloseClicked = () => {
    setShowDialog(false);
    formik.resetForm();
  };
  const onEditClicked = (input: ReceiptItem) => () => {
    setShowDialog(true);
    setIsEditMode(true);
    formik.setValues(input);
  };
  const onRemoveClicked = () => {
    setShowDialog(false);
    onChange((data.items || []).filter((i) => i.id !== formik.values.id));
    formik.resetForm();
  };
  return (
    <div className="border p-2 bg-white relative mb-2">
      <ul>
        {(data.items || []).map((item) => (
          <li
            className="py-1 border-b border-gray-100 flex justify-between items-center"
            key={item.id}
          >
            <span className="w-6/12">{item.name}:</span>
            <span className="w-2/12">
              x{item.quantity === undefined ? 1 : item.quantity}
            </span>
            <span className="flex flex-1 justify-end items-center">
              {item.price} {data.currency}
              {!readOnly && (
                <button
                  type="button"
                  className="border border-indigo-500 text-indigo-500 ml-2"
                  onClick={onEditClicked(item)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                  </svg>
                </button>
              )}
            </span>
          </li>
        ))}
        <li className="mt-2 text-right">
          {!readOnly && (
            <button
              onClick={onAddNewClicked}
              type="button"
              className="flex inline-flex items-center text-white border bg-indigo-500 p-1"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 inline-block"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9 13h6m-3-3v6m5 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                />
              </svg>
              New item
            </button>
          )}
        </li>
      </ul>
      <div className="border-t border-indigo-500 mt-2 pt-2 flex justify-between">
        <span>Total: </span>
        <span>
          {formatNumber(data.amount)} {data.currency}
        </span>
      </div>
      <Popup onBackDropClicked={onCloseClicked} visible={showDialog}>
        <h4 className="text-xl mb-2 flex justify-between">Receipt Item</h4>
        <FormTextRow
          label="Name"
          value={formik.values.name}
          onChange={formik.handleChange}
          onSuggestionClicked={(txt) => formik.setFieldValue("name", txt)}
          autoSuggest
          name="name"
          className="border-t"
          textFieldProps={{
            className: "!bg-gray-100",
          }}
        />
        <div className="relative">
          <FormCurrencyRow
            label="Price"
            value={formik.values.price}
            onChange={formik.handleChange}
            name="price"
            currency={data.currency}
            textFieldProps={{
              className: "!bg-gray-100",
            }}
          />
        </div>
        <FormTextRow
          label="Quantity"
          value={
            formik.values.quantity === undefined ? 1 : formik.values.quantity
          }
          onChange={(e) => {
            console.log(e.target.value);
            formik.handleChange(e);
          }}
          name="quantity"
          textFieldProps={{
            type: "number",
            className: "!bg-gray-100",
          }}
        />
        <div className="mt-4">
          <button
            onClick={onSaveClicked}
            type="button"
            className="bg-indigo-500 text-white px-4 py-1"
          >
            Save
          </button>
          {isEditMode && (
            <button
              onClick={onRemoveClicked}
              type="button"
              className="ml-1 bg-red-500 text-white px-4 py-1"
            >
              Remove
            </button>
          )}
        </div>
      </Popup>
    </div>
  );
};

export default ReceiptMode;
