import { FunctionComponent } from "react";
import { SessionUser } from "../types/session";
import TextField from "./TextField";

interface UserSelectListProps {
  items: Array<SessionUser>;
  value: Array<SessionUser>;
  name: string;
  showEditTotalInGroup?: boolean;
  onSelectAllClicked: React.MouseEventHandler<HTMLButtonElement>;
  onClearAllClicked: React.MouseEventHandler<HTMLButtonElement>;
  onUserCheckChanged: React.ChangeEventHandler<HTMLInputElement>;
  onUserChanged?: (u: SessionUser) => void;
}

const UserSelectList: FunctionComponent<UserSelectListProps> = ({
  items,
  value,
  name,
  onSelectAllClicked,
  onClearAllClicked,
  onUserCheckChanged,
  showEditTotalInGroup,
  onUserChanged,
}) => {
  const onTotalInGroupChanged =
    (u: SessionUser): React.ChangeEventHandler<HTMLInputElement> =>
      (e) => {
        if (onUserChanged) {
          const totalInGroup = +e.target.value;
          const toUpdate = { ...u, totalInGroup, isGroup: totalInGroup > 1 };
          onUserChanged(toUpdate);
        }
      };
  
  return (
    <>
      <div className="py-2 flex justify-between text-sm text-indigo-600">
        <button type="button" onClick={onSelectAllClicked}>
          Select all
        </button>
        <button type="button" onClick={onClearAllClicked}>
          Clear all
        </button>
      </div>
      <ul>
        <li className="px-2 py-2 border-t flex last:border-b justify-between text-sm font-bold text-gray-600">
          <div>Name</div>
          {showEditTotalInGroup && <div>Number of shares</div>}
        </li>
        {items.map((originalUser) => {
          const current = value.find((u) => u.id === originalUser.id);
          const isSelected = !!current;
          const effectiveUser = current || originalUser;

          return (
            <li
              className="px-2 py-2 border-t flex last:border-b justify-between"
              key={originalUser.id}
            >
              <div className="w-9/12 flex items-center">
                <input
                  id={`ckb_${name}_${originalUser.id}`}
                  type="checkbox"
                  value={originalUser.id}
                  className="mr-2"
                  checked={isSelected}
                  onChange={onUserCheckChanged}
                />
                <label className="flex-1" htmlFor={`ckb_${name}_${originalUser.id}`}>
                  {originalUser.name}
                  {originalUser.isGroup && originalUser.totalInGroup! > 1 && (
                    <span className="ml-1">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5 inline-block"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth={2}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
                        />
                      </svg>
                      ({originalUser.totalInGroup})
                    </span>
                  )}
                </label>
              </div>
              <div className="flex-1 ">
                {showEditTotalInGroup && (
                  <TextField
                    defaultValue={effectiveUser.totalInGroup || 1}
                    type="number"
                    inputMode="decimal"
                    className="!bg-gray-100 text-right"
                    readOnly={!isSelected}
                    onChange={onTotalInGroupChanged(effectiveUser)}
                  />
                )}
              </div>
            </li>
          );
        })}
      </ul>
    </>
  );
};

export default UserSelectList;
