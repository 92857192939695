import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";

import { Expense, Session, ShoppingItem, ShoppingList } from "../types/session";
import SessionContext from "../context/SessionContext";
import { generateId } from "../utils/idUtils";
import useAppContext from "../hooks/useAppContext";
import * as SessionRepository from "../repositories/SessionRepository";
import {
  formatFriendlyDateTime,
  formatFriendlyDateTimeWithoutDay,
} from "../utils/dateUtils";
import { getAvatar } from "../utils/userUtils";
import { Unsubscribe } from "firebase/firestore";
import { indexSession } from "../lib/search";

const withSessionContext =
  (Component: React.FunctionComponent) =>
    (props: any): React.ReactElement => {
      const appContext = useAppContext();
      const navigate = useNavigate();
      const currentEmail = appContext.user!.email;
      const today = new Date();
      const currentDate = formatFriendlyDateTimeWithoutDay(today.toString());
      const defaultSession: Session = {
        name: `Share session - ${formatFriendlyDateTime(today.toString())}`,
        when: currentDate,
        location: "",
        sharedBy: [
          {
            name: appContext.user!.name || "",
            email: currentEmail,
            id: appContext.user!.id,
          },
        ],
        sharedByIds: [appContext.user!.id],
        note: "",
        id: generateId(),
        status: "open",
        createdBy: currentEmail,
        createdDate: currentDate,
        lastUpdatedBy: currentEmail,
        lastUpdatedDate: currentDate,
        currency: appContext.user?.currency,
        rate: 1,
        expenses: [],
      };
      const params = useParams<{ id: string }>();
      const location = useLocation();
      const sessionFromState = location.state as Session;
      const [session, setSession] = useState<Session | null>(
        params.id ? sessionFromState || null : defaultSession
      );
      const [shoppingList, setShoppingList] = useState<ShoppingList>();
      const saveSession = async (session: Session) => {
        SessionRepository.saveSession(session);
        setSession({ ...session });
      };
      const addExpense = async (expense: Expense) => {
        if (!session) return;
        session.expenses = session.expenses || [];
        session.expenses.push(expense);
        saveSession(session);
      };
      const saveExpense = async (expense: Expense) => {
        if (!session) return;
        const index = session.expenses!.findIndex((e) => e.id === expense.id);
        session.expenses!.splice(index, 1, expense);
        saveSession(session);
      };
      const removeExpense = async (expense: Expense) => {
        if (!session) return;
        const index = session.expenses!.findIndex((e) => e.id === expense.id);
        session.expenses!.splice(index, 1);
        saveSession(session);
      };

      const addShoppingItem = async (item: ShoppingItem) => {
        let newShoppingList: ShoppingList = shoppingList || {
          id: generateId(),
          items: []
        }

        newShoppingList.items.push(item);
        SessionRepository.saveShoppingList(newShoppingList).then(() => {
          setShoppingList(newShoppingList);
        })
      };
      const saveShoppingItem = async (item: ShoppingItem) => {
        if (!shoppingList) return;
        const index = shoppingList?.items.findIndex((e) => e.id === item.id);
        shoppingList?.items.splice(index!, 1, item);

        SessionRepository.saveShoppingList(shoppingList).then(() => {
          setShoppingList(shoppingList);
        })
      };
      const saveShoppingItems = async (items: Array<ShoppingItem>) => {
        if (!shoppingList) return;
        items.forEach(item => {
          const index = shoppingList?.items.findIndex((e) => e.id === item.id);
          shoppingList?.items.splice(index!, 1, item);
        })

        SessionRepository.saveShoppingList(shoppingList).then(() => {
          setShoppingList(shoppingList);
        })
      };
      const removeShoppingItem = async (item: ShoppingItem) => {
        if (!shoppingList) return;
        const index = shoppingList?.items.findIndex((e) => e.id === item.id);
        shoppingList?.items.splice(index!, 1);
        SessionRepository.saveShoppingList(shoppingList).then(() => {
          setShoppingList(shoppingList);
        })
      };

      const removeSession = async (session: Session) => {
        await SessionRepository.removeSession(session.id);
        navigate("/");
      };

      useEffect(() => {
        if (params.id) {
          let unsub: Unsubscribe | null = null;
          let unsubShopping: Unsubscribe | null = null;
          if (!sessionFromState) {
            unsub = SessionRepository.subscribeSession(
              params.id,
              (sessionFromDb) => {
                if (sessionFromDb?.when)
                  sessionFromDb.when = formatFriendlyDateTimeWithoutDay(
                    sessionFromDb.when
                  );
                Promise.all(sessionFromDb.sharedBy.map(async (s) => {
                  if (s.email)
                    await getAvatar(s.email)
                }));
                setSession(sessionFromDb);
                indexSession(sessionFromDb);
              }
            );
          }
          
          unsubShopping = SessionRepository.subscribeShoppingList(params.id, (shopping) => {
            if (shopping) setShoppingList(shopping);
            else {
              setShoppingList({
                id: params.id!,
                items: []
              })
            }
          });
          return () => {
            if (unsub) unsub()
            if (unsubShopping) unsubShopping()
          };
        }
      }, [params.id, sessionFromState]);
      return (
        <SessionContext.Provider
          value={{
            session,
            setSession,
            addExpense,
            saveExpense,
            removeExpense,
            saveSession,
            removeSession,
            shoppingList,
            addShoppingItem,
            removeShoppingItem,
            saveShoppingItem,
            saveShoppingItems,
          }}
        >
          {session ? (
            <Component {...props} />
          ) : (
            <div className="bg-white flex items-center justify-center h-screen">
              Loading...
            </div>
          )}
        </SessionContext.Provider>
      );
    };

export default withSessionContext;
