import { FunctionComponent } from "react";
import { SessionUser } from "../types/session";
import { getColorForUser } from "../utils/colorUtils";
import { sortUsers } from "../utils/sessionUtils";

interface UserTagsProps {
  value: Array<SessionUser>;
  className?: string;
  readOnly?: boolean;
  onEditClicked: (
    item: SessionUser
  ) => React.MouseEventHandler<HTMLButtonElement>;
  onRemoveClicked: (
    item: SessionUser
  ) => React.MouseEventHandler<HTMLButtonElement>;
}

const UserTags: FunctionComponent<UserTagsProps> = ({
  value,
  readOnly,
  onEditClicked,
  onRemoveClicked,
  className,
}) => {
  const users = sortUsers(value);
  return (
    <div className={`flex flex-wrap ${className}`}>
      {!value.length && <span className="">&nbsp;</span>}
      {users.map((u) => (
        <span
          key={u.id}
          className={`items-center mr-1 text-white px-1 ${value.length > 3 && "mb-1"
            } ${getColorForUser(u)}`}
        >
          <button onClick={!readOnly? onEditClicked(u) : () => {}} type="button">
            {u.name}
          </button>
          {!readOnly && <button onClick={onRemoveClicked(u)} type="button" className="ml-1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-4 w-4"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>}
        </span>
      ))}
    </div>
  );
};

export default UserTags;
