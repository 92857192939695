import React from 'react';
import { Session } from '../types/session';

export interface IListContext {
  items: Array<Session>
}

export default React.createContext<IListContext>({
  items: []
});
