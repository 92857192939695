import React from 'react';
import './App.css';
import Routes from './Routes';
import { BrowserRouter } from "react-router-dom";
import withAppContext from "./hoc/withAppContext";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes />
      </BrowserRouter>
    </div>
  );
}

export default withAppContext(App);
