import { FunctionComponent, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";

import AuthenticatedLayout from "../components/AuthenticatedLayout";
import FormTextRow from "../components/FormTextRow";
import FormTextrAreaRow from "../components/FormTextrAreaRow";
import Button from "../components/Button";
import SessionContext from "../context/SessionContext";
import { useFormik } from "formik";
import ErrorFeedback from "../components/ErrorFeedback";
import SessionUserForm from "../components/SessionForm/SessionUserForm";
import FormDateTimeField from "../components/FormDateTimeField";
import Notification from "../components/Notification";
import { Expense, SessionUser } from "../types/session";
import { formatFriendlyDateTimeWithoutDay } from "../utils/dateUtils";
import useAppContext from "../hooks/useAppContext";
import { doIHavePermission, getUser } from "../utils/sessionUtils";
import FormRow from "../components/FormRow";
import { currencyOpts } from "../constants/currencies";
import SelectField from "../components/SelectField";
import TextField from "../components/TextField";
import { Group } from "../types/user";

interface SessionFormProps {}

const updateExpenseUsers = (
  users: Array<SessionUser>,
  expenses: Array<Expense>
) =>
  expenses.map((ex) => {
    const toUpdate = { ...ex };
    toUpdate.paidBy = getUser(toUpdate.paidBy.id, users) || toUpdate.paidBy;
    toUpdate.sharedBy = toUpdate.sharedBy.map((s) => {
      const toUpdateUser = { ...s };
      const u = getUser(s.id, users);
      if (u) {
        toUpdateUser.email = u.email;
        toUpdateUser.name = u.name;
        if (u.isGroup && !toUpdateUser.isGroup) {
          toUpdateUser.isGroup = u.isGroup;
          toUpdateUser.totalInGroup = u.totalInGroup;
        }
      }
      return toUpdateUser;
    });
    return toUpdate;
  });

const SessionSchema = Yup.object().shape({
  name: Yup.string().required("Required"),
  when: Yup.string(),
  location: Yup.string(),
  sharedBy: Yup.array(),
  status: Yup.string(),
  rate: Yup.number().positive(),
});

const SessionForm: FunctionComponent<SessionFormProps> = () => {
  const navigate = useNavigate();
  const { session, saveSession } = useContext(SessionContext);
  const params = useParams<{ id: string }>();
  const appContext = useAppContext();
  const isEditMode = Boolean(params.id);
  const currentEmail = appContext.user!.email;
  const currentDate = formatFriendlyDateTimeWithoutDay(new Date().toString());

  const formik = useFormik({
    initialValues: session!,
    validateOnBlur: true,
    validateOnChange: false,
    validationSchema: SessionSchema,
    onSubmit: (values) => {
      appContext.hideNotification();
      formik.validateForm();
      if (formik.isValid) {
        formik.values.lastUpdatedDate = currentDate;
        formik.values.lastUpdatedBy = currentEmail;
        values.sharedByIds = values.sharedBy.map((s) => s.email || s.id);
        saveSession(values)
          .then(() => {
            appContext.showSuccessNotification(
              "Successfully saved!",
              "Session is saved",
              () => {
                navigate(`/session/${session!.id}`);
              }
            );
          })
          .catch((err) => {
            appContext.showErrorNotification(
              "Unsuccessfully saved!",
              "Session is not saved"
            );
          });
      }
    },
  });
  const errors = Object.entries(formik.errors);
  const onDateChanged = (value: string) => {
    formik.setFieldValue("when", value);
  };
  const onUsersChanged = (value: Array<SessionUser>) => {
    formik.setFieldValue("sharedBy", value);
    formik.values.expenses = updateExpenseUsers(
      value,
      formik.values.expenses || []
    );
  };
  const readOnly = !doIHavePermission(
    appContext.user,
    session?.sharedBy,
    session?.createdBy
  );
  const onCurrencyChange: React.ChangeEventHandler<HTMLSelectElement> = (e) => {
    formik.values.expenses = (formik.values.expenses || []).map((ex) => ({
      ...ex,
      currency: e.target.value,
    }));
    if (e.target.value === appContext.user?.currency) {
      formik.values.rate = 1;
    }
    formik.handleChange(e);
  };
  const onGroupClicked = (group: Group) => () => {
    formik.setFieldValue('sharedBy', group.users);
  }
  return (
    <AuthenticatedLayout
      pageTitle={isEditMode ? "Edit session" : "New share session"}
    >
      <p className="italic text-left text-gray-600 px-4">
        A split share session can be a trip, a party which several persons pay
        for costs of the party,..
      </p>
      <form onSubmit={formik.handleSubmit} className="text-left mt-4">
        <FormTextRow
          name="name"
          value={formik.values.name}
          onChange={formik.handleChange}
          className="flex-wrap"
          textFieldProps={{
            readOnly,
            placeholder: "Session name",
            className: "font-bold",
          }}
        />

        <FormDateTimeField
          label="When"
          value={formik.values.when}
          onChange={onDateChanged}
        />
        <FormTextRow
          name="location"
          label="Location"
          value={formik.values.location}
          onChange={formik.handleChange}
          textFieldProps={{
            readOnly,
          }}
        />
        <FormRow inputWrapperClassName="flex items-center" label="Currency">
          <SelectField
            name="currency"
            items={currencyOpts}
            value={formik.values.currency}
            onChange={onCurrencyChange}
            disabled={appContext.isLoading}
            className="w-5/12"
          />
          {formik.values.currency &&
            formik.values.currency !== appContext.user?.currency && (
              <>
                <label className="px-2 text-sm text-black/[0.7] text-right">
                  Rate {formik.values.currency}/{appContext.user?.currency}
                </label>
                <TextField
                  name="rate"
                  value={
                    formik.values.rate === undefined ? 1 : formik.values.rate
                  }
                  onChange={formik.handleChange}
                  className="w-4/12"
                  {...{
                    readOnly,
                    type: "number",
                    inputMode: "decimal",
                  }}
                />
              </>
            )}
        </FormRow>

        <FormRow label="Shared by">
          <SessionUserForm
            onChange={onUsersChanged}
            value={formik.values.sharedBy || []}
          />
          <div className="mt-2">
            {appContext.groups.map((g) => (
              <button
                className="text-sm border border-indigo-500 px-2 rounded-full mr-1"
                key={g.id}
                onClick={onGroupClicked(g)}
                type="button"
              >
                {g.name}
              </button>
            ))}
          </div>
        </FormRow>
        <FormTextrAreaRow
          name="note"
          label="Note"
          value={formik.values.note}
          onChange={formik.handleChange}
          textFieldAreaProps={{
            readOnly,
          }}
        />
        <div className="flex justify-center mt-4">
          <Button disabled={readOnly} className="px-4" type="submit">
            {isEditMode ? "Save" : "Create"}
          </Button>
        </div>
        <Notification />
        {Boolean(errors.length) && <ErrorFeedback errors={errors} />}
      </form>
    </AuthenticatedLayout>
  );
};

export default SessionForm;
