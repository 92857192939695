import { FunctionComponent } from "react";
import { Link } from "react-router-dom";

interface IntroProps { }

const Intro: FunctionComponent<IntroProps> = () => {
  return (
    <>
      <div className="bg-white p-4 pt-14">
        <p className="bg-indigo-100 rounded-full text-gray-600 inline-block px-4 py-1 font-semibold">
          Effective split bills for groups
        </p>
        <h2 className="text-4xl mt-8 leading-relaxed">
          Split expenses between groups, <br /> without the
          <span className="relative inline-block">
            <span className="rotate-3 bg-indigo-500 absolute top-0 left-0 right-0 bottom-0"></span>
            <span className="relative z-10 text-white px-2">hassle work</span>
          </span>
        </h2>
        <p className="mt-4 lg:px-32 text-gray-600">
          From parties, trips,... where need to share expenses between multiple
          persons in groups this tool can help without troubles.
        </p>
        <div className="mt-8 lg:px-32">
          <div className="bg-video relative m-auto bg-gray-200">
            <iframe
              src="https://player.vimeo.com/video/690673068?h=94400078bd"
              width="100%"
              height="100%"
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowFullScreen
              title="SplittaExpens"
            ></iframe>
          </div>
          <Link
            to="/"
            className="rounded-full border px-8 py-2 mt-4 inline-block text-2xl"
          >
            Explore
          </Link>
        </div>

      </div>
      <div className="px-4 mt-16">
        <h2 className="text-4xl font-semibold">Explore the features</h2>
        <div className="grid text-white mt-16 flex-wrap text-left gap-4 md:grid-cols-3 bg-gray-100">
          <div className="p-8 rounded-lg shadow-md bg-gradient-to-r from-violet-500 to-fuchsia-500">
            <h4 className="text-xl font-semibold">Group expenses by occasions</h4>
            <ul className="mt-4 ml-8 list-disc font-medium">
              <li>
                Occasions can be <span className="border-b-2">a trip</span>,{" "}
                <span className="border-b-2">a party</span> or just when you need
                to share expenses
              </li>
              <li className="mt-2">
                Persons to share in a occasion can be{" "}
                <span className="border-b-2">a group</span> like a family or just{" "}
                <span className="border-b-2">an individual</span>
              </li>
            </ul>
          </div>
          <div className="p-8 rounded-lg mt-4 shadow-md md:mt-0 bg-gradient-to-r from-orange-500 to-amber-500">
            <h4 className="text-xl font-semibold">Detail summary</h4>
            <ul className="mt-4 ml-8 list-disc font-medium">
              <li>
                Summary report is <span className="border-b-2">clear</span>,{" "}
                <span className="border-b-2">detailed</span> and{" "}
                <span className="border-b-2">easy</span> to see who will pay and
                who will get back.
              </li>
              <li className="mt-2">
                Filter by person function helps to{" "}
                <span className="border-b-2">verify</span> expenses.
              </li>
            </ul>
          </div>
          <div className="p-8 rounded-lg mt-4 shadow-md md:mt-0 bg-gradient-to-r from-indigo-500 to-sky-500">
            <h4 className="text-xl font-semibold">Easy to declare expenses</h4>
            <ul className="mt-4 ml-8 list-disc font-medium">
              <li>
                Receipt mode helps to declare{" "}
                <span className="border-b-2">detailed</span>,{" "}
                <span className="border-b-2">clear</span> expenses.
              </li>
              <li className="mt-2">
                Declare how many persons to share easily by{" "}
                <span className="border-b-2">group</span> or{" "}
                <span className="border-b-2">individual</span>.
              </li>
            </ul>
          </div>
        </div>
        <div className="h-16"></div>

      </div>
    </>
  );
};

export default Intro;
