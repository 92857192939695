import { FunctionComponent } from "react";

interface FormRowProps {
  children?: React.ReactNode;
  className?: string;
  label?: string;
  inputWrapperClassName?: string;
}

const FormRow: FunctionComponent<FormRowProps> = ({
  label,
  className,
  children,
  inputWrapperClassName,
}) => {
  return (
    <div className={`flex items-center px-4 border-b py-4 ${className}`}>
      {!!label && (
        <label className="text-sm text-black/[0.7] basis-1/4">{label}</label>
      )}
      <div className={`flex-1 ${inputWrapperClassName}`}>
        {children}
      </div>
    </div>
  );
};

export default FormRow;
