import { FunctionComponent } from "react";
import { Outlet } from "react-router-dom";
import withSessionContext from "../hoc/withSessionContext";

interface SessionWrapperProps {
  children?: React.ReactNode;
}
 
const SessionWrapper: FunctionComponent<SessionWrapperProps> = ({ children }) => {
  return (<Outlet />);
}
 
export default withSessionContext(SessionWrapper);