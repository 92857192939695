import { FunctionComponent, useContext } from "react";
import ListContext from "../../context/ListContext";
import ListItem from "./ListItem";

interface SessionListProps { }

const SessionList: FunctionComponent<SessionListProps> = () => {
  const listContext = useContext(ListContext);
  const doneItems = listContext.items.filter(i => i.status === 'done');
  return (
    <ul className="mt-2 bg-black/[0.1]">
      {
        doneItems.map(item => (
          <ListItem key={item.id} item={item} className="border-green-500" />
        ))
      }
    </ul>
  );
};

export default SessionList;
