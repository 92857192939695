import { FunctionComponent } from "react";

interface ErrorFeedbackProps {
  errors?: Array<[string, any]>;
}

const ErrorFeedback: FunctionComponent<ErrorFeedbackProps> = ({ errors }) => {
  return (
    <div className="text-red-500 text-sm border-l-2 border-red-600 mt-2 p-2">
      {(errors || []).map(([key, value], id) => (
        <div key={key}>
          <span className="font-bold">{key}</span> - {value}
        </div>
      ))}
    </div>
  );
};

export default ErrorFeedback;
