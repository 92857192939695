import React, { useEffect, useState } from 'react';

import ListContext from '../context/ListContext';
import useAppContext from '../hooks/useAppContext';
import { indexSession } from '../lib/search';
import { loadSessions } from '../repositories/SessionRepository';
import { Session } from '../types/session';

const withListContext = (Component: React.FunctionComponent) =>
  (props: any): React.ReactElement => {
    const [items, setItems] = useState<Array<Session>>([])   
    const appContext = useAppContext();
    useEffect(() => {
      loadSessions(appContext.user!).then(sessions => {
        setItems(sessions);
        sessions.forEach(session => {
          indexSession(session);
        })
      })
    }, []); 
    return (
      <ListContext.Provider value={{ items }}>
        <Component {...props} />
      </ListContext.Provider>
    )
  };

export default withListContext;
