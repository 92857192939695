import React from 'react';
import { Navigate } from 'react-router-dom';

import useAppContext from '../hooks/useAppContext';

const withProtectedRoute = (Component: React.FunctionComponent) =>
  (props: any): React.ReactElement => {
    const appState = useAppContext();
    return (
      appState.user ? <Component {...props}>{props.children}</Component > : <Navigate to="/login" />
    )
};

export default withProtectedRoute;
