import { FormEventHandler, FunctionComponent } from "react";
import FormRow from "./FormRow";
import TextAreaField, { TextAreaFieldProps } from "./TextAreaField";

interface FormTextrAreaRowProps {
  label: string;
  value: string;
  textFieldAreaProps?: TextAreaFieldProps;
  name?: string;  
  onChange?: FormEventHandler<HTMLTextAreaElement>;
}

const FormTextrAreaRow: FunctionComponent<FormTextrAreaRowProps> = ({
  label,
  value,
  name,
  onChange,
  textFieldAreaProps,
}) => {
  return (
    <FormRow label={label}>      
      <TextAreaField
        {...textFieldAreaProps}
        onChange={onChange}
        placeholder={label}
        value={value}
        name={name}
      />
    </FormRow>
  );
};

export default FormTextrAreaRow;
