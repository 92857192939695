import { FunctionComponent, useContext } from "react";
import { get } from "lodash/fp";
import SessionContext from "../../context/SessionContext";
import { getReport, roundAmount, sortUsers } from "../../utils/sessionUtils";

interface SummaryProps { }

const Summary: FunctionComponent<SummaryProps> = () => {
  const { session } = useContext(SessionContext);
  const report = getReport(session);
  const currency = session?.currency || get("expenses[0].currency", session);
  let sum = 0;
  return (
    <>
      <ul>
        <li className="mb-1 border-b py-1">
          <div className="flex justify-between">
            <div className="font-bold uppercase text-sm">
              Person
            </div>
            <div className="font-bold uppercase text-sm">
              Balance
            </div>
          </div>
        </li>
        {sortUsers(session?.sharedBy).map((u) => {
          const reportPerUser = report.details.get(u.email || u.id);
          const amount = roundAmount(reportPerUser?.balance || 0, currency);
          sum += amount;
          return (
            <li className="mb-1 border-b py-1" key={u.id}>
              <div className="flex">
                <span className="w-6/12 flex inline-flex items-center">
                  {u.name}
                  {u.isGroup && u.totalInGroup! > 1 && (
                    <span className="ml-1">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5 inline-block"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth={2}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
                        />
                      </svg>
                      ({u.totalInGroup})
                    </span>
                  )}
                  :
                </span>
                <span className="flex-1 text-right">
                  <span
                    className={`mr-1 font-bold ${amount >= 0 ? "text-green-600" : "text-red-600"
                      }`}
                  >
                    {amount}
                  </span>
                  {currency}
                </span>
              </div>
              <div className="text-sm">
                Paid: <span className="font-bold">{reportPerUser?.paid}{currency}</span>
              </div>
            </li>
          );
        })}
        <li className="flex justify-between font-bold">
          <div>Total: {session?.sharedBy.length}</div>
          <div>
            Sum: {sum} {currency}
            {Math.abs(sum) > 5 && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 inline-block text-red-500"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                />
              </svg>
            )}
          </div>
        </li>
        <li className="flex justify-between">
          <div>
            Total persons to share:{" "}
            <span className="font-bold">{report.totalUsersToShare}</span>
          </div>
        </li>
        <li className="flex justify-between">
          <div>
            Total spent:
            <span className="font-bold">
              {report.totalSpent} {currency}
            </span>
          </div>
        </li>
      </ul>
    </>
  );
};

export default Summary;
