import { FunctionComponent } from "react";
import { textFieldBaseStyles } from "./formStyles";

export interface TextFieldProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {}

const TextField: FunctionComponent<TextFieldProps> = ({className, ...props}) => {
  return (
    <input {...props} className={`${textFieldBaseStyles} ${className}`} />
  );
};

export default TextField;
