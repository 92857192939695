import { FormEventHandler, FunctionComponent } from "react";
import CurrencyField from "./CurrencyField";
import FormRow from "./FormRow";
import { TextFieldProps } from "./TextField";

interface FormCurrencyRowProps {
  label?: string;
  name?: string;
  value?: string | number;
  defaultValue?: string | number;
  textFieldProps?: TextFieldProps;
  onChange?: FormEventHandler<HTMLInputElement>;
  className?: string;
  currency: string;
}

const FormCurrencyRow: FunctionComponent<FormCurrencyRowProps> = ({
  label,
  name,
  className,
  value,
  defaultValue,
  textFieldProps,
  onChange,
  currency,
}) => {
  return (
    <FormRow label={label} className={className}>
      <CurrencyField
        {...{ placeholder: label, ...textFieldProps }}
        onChange={onChange}
        name={name}
        value={value}
        defaultValue={defaultValue}
        currency={currency}
      />     
    </FormRow>
  );
};

export default FormCurrencyRow;
