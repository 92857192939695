import { useFormik } from "formik";
import { FunctionComponent, useContext } from "react";
import { isArray } from 'lodash/fp';
import AuthenticatedLayout from "../components/AuthenticatedLayout";
import * as Yup from "yup";

import Button from "../components/Button";
import FormTextRow from "../components/FormTextRow";
import SessionContext from "../context/SessionContext";
import { generateId } from "../utils/idUtils";
import {
  Expense,
  ReceiptItem,
  SessionUser,
  ShoppingItem,
} from "../types/session";
import useAppContext from "../hooks/useAppContext";
import { currencies } from "../constants/currencies";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import SessionUserSelect from "../SessionUserSelect";
import ErrorFeedback from "../components/ErrorFeedback";
import Notification from "../components/Notification";
import { formatFriendlyDateTimeWithoutDay } from "../utils/dateUtils";
import FormDateTimeField from "../components/FormDateTimeField";
import ReceiptMode from "../components/Expense/ReceiptMode";
import FormRow from "../components/FormRow";
import CurrencyField from "../components/CurrencyField";
import {
  doIHavePermission,
  getTotalPersonsToShare,
  getUser,
} from "../utils/sessionUtils";

interface ExpenseProps {}

const ExpenseSchema = Yup.object().shape({
  name: Yup.string().required("Required"),
  paidBy: Yup.object().required("PaidBy is required"),
  amount: Yup.number(),
  sharedWith: Yup.array(),
});

const renderEmptyRightButton = () => <span>&nbsp;</span>;
const renderEmptyBottomButton = () => <span>&nbsp;</span>;

const ExpenseForm: FunctionComponent<ExpenseProps> = () => {
  const sessionContext = useContext(SessionContext);
  const appContext = useAppContext();
  const navigate = useNavigate();
  const params = useParams<{ expenseId: string }>();

  const isEditMode = Boolean(params.expenseId);
  const currentEmail = appContext.user!.email;
  const currentDate = formatFriendlyDateTimeWithoutDay(new Date().toString());
  const readOnly =
    isEditMode &&
    (!doIHavePermission(
      appContext.user,
      sessionContext.session?.sharedBy,
      sessionContext.session?.createdBy
    ) ||
      sessionContext.session?.status === "done");
  const currentSessionUser = getUser(
    currentEmail,
    sessionContext.session?.sharedBy || []
  );
  const defaultSessionUser = {
    email: currentEmail,
    name: appContext.user!.name!,
    id: currentEmail,
  };
  const currentSessionCurrency = sessionContext.session?.currency;
  const location = useLocation();  
  const shopItemFromState = isArray(location.state)? location.state as Array<ShoppingItem> : null;
  const items: Array<ReceiptItem> = shopItemFromState
    ? shopItemFromState.map((s) => ({
        name: s.name,
        quantity: s.quantity,
        price: 0,
        id: s.id,
      }))
    : [];
  const defaultExpense: Expense = {
    name: "",
    paidBy: currentSessionUser || defaultSessionUser,
    amount: 0,
    sharedBy: sessionContext.session?.sharedBy || [],
    id: generateId(),
    currency:
      currentSessionCurrency || appContext.user!.currency || currencies[0],
    when: currentDate,
    createdBy: currentEmail,
    createdDate: currentDate,
    lastUpdatedBy: currentEmail,
    lastUpdatedDate: currentDate,
    mode: shopItemFromState ? "Receipt" : "Normal",
    items,
  };
  const expense = isEditMode
    ? sessionContext.session?.expenses?.find((e) => e.id === params.expenseId)!
    : defaultExpense;

  const formik = useFormik<Expense>({
    initialValues: expense,
    validateOnBlur: true,
    validateOnChange: false,
    validationSchema: ExpenseSchema,
    onSubmit: (values) => {
      formik.validateForm();
      appContext.hideNotification();
      if (formik.isValid) {
        formik.values.lastUpdatedDate = currentDate;
        formik.values.lastUpdatedBy = currentEmail;
        if (isEditMode) {
          sessionContext
            .saveExpense(values)
            .then(() => {
              appContext.showSuccessNotification(
                "Successfully saved!",
                "Expense is saved",
                () => navigate(`/session/${sessionContext.session!.id}`)
              );
            })
            .catch((err) => {
              appContext.showErrorNotification(
                "Unsuccessfully saved!",
                "Session is not saved"
              );
            });
        } else {
          sessionContext
            .addExpense(values)
            .then(() => {
              appContext.showSuccessNotification(
                "Successfully saved!",
                "Expense is saved"
              );
              navigate(`/session/${sessionContext.session!.id}`);
            })
            .catch((err) => {
              appContext.showErrorNotification(
                "Unsuccessfully saved!",
                "Session is not saved"
              );
            });
        }
      }
    },
  });

  const onUsersChanged = (value: Array<SessionUser>) => {
    formik.setFieldValue("sharedBy", value);
  };
  const onPaidByChanged = (value: Array<SessionUser>) => {
    const paidBy = value.pop();

    formik.setFieldValue("paidBy", paidBy);
  };
  const onRemoveClicked = () => {
    sessionContext.removeExpense(expense);
    navigate(`/session/${sessionContext.session!.id}`);
  };

  const onDateChanged = (value: string) => {
    formik.setFieldValue("when", value);
  };

  const onReceiptsChanged = (value: Array<ReceiptItem>) => {
    formik.setFieldValue("items", value);
    const sum = value.reduce(
      (acc, curr) =>
        acc + curr.price * (curr.quantity === undefined ? 1 : curr.quantity),
      0
    );
    formik.setFieldValue("amount", sum);
  };

  const errors = Object.entries(formik.errors);

  return (
    <AuthenticatedLayout
      showTopTitle={isEditMode}
      pageTitle={isEditMode ? "Expense detail" : "New Expense"}
      renderRightButton={renderEmptyRightButton}
      renderBottomButton={renderEmptyBottomButton}
    >
      {!isEditMode && (
        <p className="italic text-left text-gray-600 px-4">
          Declare expense for the session. You can use receipt mode for adding
          multiple items
        </p>
      )}
      <div className="px-4 mt-4 text-left text-sm font-bold uppercase">
        {sessionContext.session?.name}
      </div>
      <div className="form text-left">
        <form onSubmit={formik.handleSubmit}>
          <FormTextRow
            name="name"
            value={formik.values?.name}
            onChange={formik.handleChange}
            textFieldProps={{
              placeholder: "What is it for?",
              className: "font-bold",
              readOnly,
            }}
            suggestions={[]}
            onSuggestionClicked={(txt) => formik.setFieldValue("name", txt)}
            autoSuggest
          />
          <FormDateTimeField
            label="When"
            value={formik.values.when}
            onChange={onDateChanged}
            className="inline-block"
          />
          <FormRow label="Paid by">
            <SessionUserSelect
              onChange={onPaidByChanged}
              items={sessionContext.session?.sharedBy || []}
              value={formik.values.paidBy ? [formik.values.paidBy] : []}
              className="mt-2"
              key="paidBy"
              name="paidBy"
              readOnly={readOnly}
              isSingleChoice
            />
          </FormRow>

          <FormRow className="" label="Shared by">
            <div className="w-full">
              <SessionUserSelect
                onChange={onUsersChanged}
                items={sessionContext.session?.sharedBy || []}
                value={formik.values.sharedBy}
                showCustomSettings
                key="sharedBy"
                name="sharedBy"
                readOnly={readOnly}
              />
              <p className="text-xs mt-1">
                Total persons to share:{" "}
                {getTotalPersonsToShare(formik.values.sharedBy)}
              </p>
            </div>
          </FormRow>

          <FormRow label="Amount">
            <div className="w-full">
              <div className="border border-indigo-400 radius-md flex inline-flex text-sm flex-inline mb-1">
                <div>
                  <input
                    defaultChecked={
                      !formik.values.mode || formik.values.mode === "Normal"
                    }
                    id="ckb_NormalMode"
                    className="hidden peer"
                    type="radio"
                    name="mode"
                    onChange={formik.handleChange}
                    value="Normal"
                  />
                  <label
                    htmlFor="ckb_NormalMode"
                    className="inline-block peer-checked:bg-indigo-400 peer-checked:text-white px-2"
                  >
                    Standard mode
                  </label>
                </div>
                <div>
                  <input
                    id="ckb_ReceiptMode"
                    className="hidden peer"
                    type="radio"
                    name="mode"
                    onChange={formik.handleChange}
                    defaultChecked={formik.values.mode === "Receipt"}
                    value="Receipt"
                  />
                  <label
                    htmlFor="ckb_ReceiptMode"
                    className="inline-block peer-checked:bg-indigo-400 peer-checked:text-white px-2"
                  >
                    Receipt mode
                  </label>
                </div>
              </div>
              {!formik.values.mode || formik.values.mode === "Normal" ? (
                <CurrencyField
                  name="amount"
                  value={formik.values.amount}
                  onChange={formik.handleChange}
                  currency={formik.values.currency}
                  readOnly={readOnly}
                />
              ) : (
                <ReceiptMode
                  data={formik.values}
                  onChange={onReceiptsChanged}
                  readOnly={readOnly}
                />
              )}
            </div>
          </FormRow>

          {!readOnly && (
            <div className="mt-4 justify-center flex">
              <Button type="submit" className="px-4">
                Save
              </Button>
              {isEditMode && (
                <Button
                  onClick={onRemoveClicked}
                  type="button"
                  className="px-4 ml-2 bg-red-500"
                >
                  Remove
                </Button>
              )}
            </div>
          )}
          <Notification />
          {Boolean(errors.length) && <ErrorFeedback errors={errors} />}
        </form>
      </div>
    </AuthenticatedLayout>
  );
};

export default ExpenseForm;
