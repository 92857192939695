import { FunctionComponent, useContext, useState } from "react";
import AuthenticatedLayout from "../components/AuthenticatedLayout";
import FunctionBlock from "../components/Listing/FunctionBlock";
import SessionList from "../components/Listing/SessionList";
import OpenSessionList from "../components/Listing/OpenSessionList";
import BlockHeader from "../components/BlockHeader";
import useAppContext from "../hooks/useAppContext";
import withListContext from "../hoc/withListContext";
import ListContext from "../context/ListContext";
import { User } from "../types/user";
import { getReport } from "../utils/sessionUtils";
import { Session } from "../types/session";
import DetailBalances from "../components/Listing/DetailBalances";
import Popup from "../components/Popup";
import { ReactComponent as EyeIcon } from "../components/icons/eye.svg";
import { ReactComponent as SettingsIcon } from "../components/icons/cog.svg";
import { ReactComponent as ReceiptTaxIcon } from "../components/icons/receipt-tax.svg";
import { ReactComponent as UsersIcon } from "../components/icons/users.svg";
import SyncContactBlock from '../components/Listing/SyncContactBlock';

interface ListingProps { }

const calculateBalances = (user: User, sessions: Array<Session>) => {
  return sessions.reduce((acc, session) => {
    const report = getReport(session);
    const reportCurrency = session.currency;
    const rate = reportCurrency && reportCurrency !== user.currency ? session.rate || 1 : 1;
    return acc + (report.details.get(user.email)?.balance || 0) * rate;
  }, 0);
};

const Listing: FunctionComponent<ListingProps> = () => {
  const appContext = useAppContext();
  const { items } = useContext(ListContext);
  const [showDetail, setShowDetail] = useState(false);
  const lastSession = items.find((i) => i.status === "open");
  const balance = Math.round(
    calculateBalances(
      appContext.user!,
      items.filter((i) => i.status === "open")
    )
  );
  const onViewDetailClicked = () => {
    setShowDetail(true);
  };
  const onCloseViewDetail = () => {
    setShowDetail(false);
  };
  return (
    <AuthenticatedLayout disableBackButton pageTitle="SplittaXpens">
      <div className="px-4 text-left bg-gray-100 mt-2 py-4 pb-20">
        <h1 className="text-xl mt-4">
          <span>Hello</span>  <span className="text-indigo-600">{appContext.user?.name}</span>
        </h1>
        <p className="italic mt-2">You are all good, </p>
        <div className="bg-white mt-4 p-4 shadow-lg flex items-center justify-between">
          <h2 className="">
            Your balance:{" "}
            <span
              className={`underline font-bold ${balance < 0 ? "text-red-500" : "text-green-500"
                }`}
            >
              {balance}
              {appContext.user?.currency}
            </span>
          </h2>
          <button onClick={onViewDetailClicked} className="text-indigo-500">
            <EyeIcon />
          </button>
        </div>
        <div className="">
          <div className="inline-block">
            <div className="mt-4 px-4 text-2xl">Now you can</div>
          </div>
          <div className="flex-1 px-4 mt-2 bg-white shadow-lg">
            <FunctionBlock
              url="/new-session"
              label="Create a share session"
              subText="A session can be a trip, a party, or an occasion which you share expenses with others"
              className="border-orange-600"
              icon={
                <span className="relative block">
                  <ReceiptTaxIcon className="h-6 w-6 text-orange-600" />
                  <span
                    style={{
                      right: -18,
                      top: -10,
                    }}
                    className="absolute top-0 right-0 bg-yellow-200 text-xs rounded-md px-1"
                  >
                    new
                  </span>
                </span>
              }
            />
            {lastSession && (
              <>
                <div className="border-b" />
                <FunctionBlock
                  url={`/session/${lastSession.id}`}
                  label="Continue the last session"
                  subText={`Continue add expenses for ${lastSession.name}`}
                  className="border-green-600"
                  icon={<ReceiptTaxIcon className="h-6 w-6 text-green-600" />}
                />
                <div className="border-b" />
                <SyncContactBlock sessionToSync={lastSession} />
              </>
            )}

            <div className="border-b" />
            <FunctionBlock
              url="/contacts"
              label="Manage contacts and groups"
              subText="Contacts can be used in expenses or sessions. Groups can be used to select a group of people easily in sessions."
              className="border-indigo-600"
              icon={<UsersIcon className="h-6 w-6 text-indigo-600" />}
            />
            <div className="border-b" />
            <FunctionBlock
              url="/settings"
              label="Update your profile"
              subText="Update name, password"
              className="border-indigo-600"
              icon={<SettingsIcon className="h-6 w-6 text-indigo-600" />}
            ></FunctionBlock>
          </div>
        </div>
        <div className="mt-6">
          <BlockHeader
            text="Incomplete"
            textClassName="bg-orange-400 border-orange-500"
          />
          <OpenSessionList />
        </div>
        <div className="mt-6">
          <BlockHeader
            text="History"
            textClassName="bg-green-600 border-green-800"
          />
          <SessionList />
        </div>
      </div>
      <Popup visible={showDetail} onBackDropClicked={onCloseViewDetail}>
        <DetailBalances />
      </Popup>
    </AuthenticatedLayout>
  );
};

export default withListContext(Listing);
