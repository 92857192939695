import { collection, deleteDoc, doc, getDoc, getDocs, getFirestore, limit, onSnapshot, query, setDoc, where } from "firebase/firestore";
import { orderBy } from "lodash/fp";
import { Session, ShoppingList } from "../types/session";
import { User } from "../types/user";

export const loadSessions = async (u: User): Promise<Array<Session>> => {
  const db = getFirestore();
  const colRef = collection(db, "PaymentSessions");
  const q = query(colRef, where("sharedByIds", "array-contains", u.id), where("status", "==", "open"));
  const querySnapshot = await getDocs(q);
  const res: Array<Session> = [];
  querySnapshot.forEach((doc) => {
    res.push(doc.data() as Session);
  });

  const finishedSessionQuery = query(colRef, where("sharedByIds", "array-contains", u.id), where("status", "==", "done"), limit(5));
  const queryDoneSnapshot = await getDocs(finishedSessionQuery);
  const doneSessions: Array<Session> = [];
  queryDoneSnapshot.forEach((doc) => {
    doneSessions.push(doc.data() as Session);
  });
  return orderBy(s => new Date(s.when), 'desc', [...res, ...doneSessions]);
}

export const saveSession = async (session: Session) => {
  const db = getFirestore();
  await setDoc(doc(db, "PaymentSessions", session.id), session);
}

// need to check permission
export const loadSession = async (id: string): Promise<Session> => {
  const db = getFirestore();
  const docRef = doc(db, "PaymentSessions", id);
  const docSnap = await getDoc(docRef);
  return docSnap.data() as Session;
}

export const subscribeSession = (id: string, subFn: (input: Session) => void) => {
  const db = getFirestore();
  const docRef = doc(db, "PaymentSessions", id);
  const unsub = onSnapshot(docRef, (doc) => {
    subFn(doc.data() as Session);
  });
  return unsub;
}


export const removeSession = async (id: string): Promise<void> => {
  const db = getFirestore();
  const docRef = doc(db, "PaymentSessions", id);
  return deleteDoc(docRef)
}

export const subscribeShoppingList = (id: string, subFn: (input: ShoppingList) => void) => {
  const db = getFirestore();
  const docRef = doc(db, "ShoppingLists", id);
  const unsub = onSnapshot(docRef, (doc) => {
    subFn(doc.data() as ShoppingList);
  });
  return unsub;
}


export const loadShoppingList = async (id: string): Promise<ShoppingList> => {
  const db = getFirestore();
  const docRef = doc(db, "ShoppingLists", id);
  const docSnap = await getDoc(docRef);
  return docSnap.data() as ShoppingList;
}

export const saveShoppingList = async (shoppingList: ShoppingList) => {
  const db = getFirestore();
  await setDoc(doc(db, "ShoppingLists", shoppingList.id), shoppingList);
}