import { FunctionComponent } from "react";
import { SessionUser } from "../../types/session";

import UserTag from "../UserTag";

interface UserSpanProps {
  children?: React.ReactNode;
  className?: string;
  user: SessionUser;
  hideGroupInfo?: boolean;
}

const UserSpan: FunctionComponent<UserSpanProps> = ({
  children,
  className,
  user,
  hideGroupInfo,
}) => {
  
  return user? (
    <span
      className={`text-white mr-1 font-bold inline-block mb-1 ${className} border rounded-full`}
    >
      <UserTag className="mr-0" user={user} showAvatar />

      {!hideGroupInfo &&
      user.isGroup &&
      user.totalInGroup &&
      user.totalInGroup > 1
        ? <span className="text-black text-sm mx-1">({user.totalInGroup})</span>
        : ""}
      {children}
    </span>
  ): <span className="border border-red-500 text-sm font-bold px-2 rounded-full text-red-500">Missing User</span>;
};

export default UserSpan;
