import { useFormik } from "formik";
import { FunctionComponent } from "react";
import * as Yup from "yup";
import useAppContext from "../../hooks/useAppContext";

import { SessionUser } from "../../types/session";
import { generateId } from "../../utils/idUtils";
import Button from "../Button";
import ErrorFeedback from "../ErrorFeedback";
import FormTextRow from "../FormTextRow";
import Notification from "../Notification";

interface NewContactFormProps {
  onCancelClicked: () => void;
  onSaveClicked: () => void;
  data?: SessionUser;
}

const SessionUserSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email"),
  name: Yup.string().required("Required"),
  totalInGroup: Yup.number().positive(),
});

const NewContactForm: FunctionComponent<NewContactFormProps> = ({ onCancelClicked, onSaveClicked, data }) => {
  const appContext = useAppContext();
  const defaultValue = {
    id: "",
    email: "",
    name: "",
    isGroup: false,
    totalInGroup: 1,
  };
  const editMode = Boolean(data);
  const formik = useFormik<SessionUser>({
    initialValues: data || defaultValue,
    validateOnBlur: true,
    validateOnChange: false,
    validationSchema: SessionUserSchema,
    onSubmit: (values) => {
      if (!editMode) {
        values.id = values.email || generateId()
        appContext.addContact(appContext.user!, values).then(() => {
          appContext.showSuccessNotification(
            "Successfully saved!",
            "Contact is saved",
            onSaveClicked
          );
        }).catch(() => {
          appContext.showErrorNotification(
            "Unsuccessfully saved!",
            "Contact is not saved"
          );
        });
      } else {
        appContext.saveContact(appContext.user!, values).then(() => {
          appContext.showSuccessNotification(
            "Successfully saved!",
            "Contact is saved"
          );
          setTimeout(onSaveClicked, 500);
        }).catch(() => {
          appContext.showErrorNotification(
            "Unsuccessfully saved!",
            "Contact is not saved"
          );
        });
      }
    },
  });

  const onRemoveClicked = () => {
    if (data?.id)
      appContext.removeContact(appContext.user!, data?.id).then(onCancelClicked);
  }
  const onCheckedChange =
    (field: string): React.ChangeEventHandler<HTMLInputElement> =>
      (e) => {
        formik.setFieldValue(field, e.target.checked);
      };
  const errors = Object.entries(formik.errors);

  return (
    <form onSubmit={formik.handleSubmit}>
      <h4 className="border-b border-black/[0.7] pb-1 mb-2 uppercase text-black/[0.7]">
        {editMode ? "Edit friend" : "Add new friend"}
      </h4>
      <FormTextRow
        name="name"
        label="Name"
        onChange={formik.handleChange}
        value={formik.values.name}
        textFieldProps={{
          className: '!bg-gray-100'
        }}
      />
      <FormTextRow
        name="email"
        label="Email"
        onChange={formik.handleChange}
        value={formik.values.email}
        textFieldProps={{
          type: "email",
          className: '!bg-gray-100'
        }}
      />
      <div className="mt-2">
        <input
          id="ckb-isGroup"
          onChange={onCheckedChange("isGroup")}
          name="isGroup"
          type="checkbox"
          checked={Boolean(formik.values.isGroup)}
        />
        <label htmlFor="ckb-isGroup" className="ml-2">
          This is a group
        </label>
      </div>
      {formik.values.isGroup && (
        <FormTextRow
          label="Persons in group?"
          value={formik.values.totalInGroup || ""}
          onChange={formik.handleChange}
          name="totalInGroup"
          textFieldProps={{
            type: "number",
            inputMode: "numeric",
            className: '!bg-gray-100'
          }}
        />
      )}
      <div className="flex justify-center">
        <Button type="submit" className="mt-2 px-4">
          Save
        </Button>
        {editMode && <Button type="button" onClick={onRemoveClicked} className="mt-2 ml-2 px-4 bg-red-500">
          Remove
        </Button>}
        <Button type="button" onClick={onCancelClicked} className="mt-2 ml-2 px-4 bg-red-500">
          Cancel
        </Button>
      </div>
      <Notification />
      {Boolean(errors.length) && <ErrorFeedback errors={errors} />}
    </form>
  );
};

export default NewContactForm;
