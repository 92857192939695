import { FunctionComponent, useContext } from "react";
import ListContext from "../../context/ListContext";
import ListItem from "./ListItem";

interface OpenSessionListProps { }

const OpenSessionList: FunctionComponent<OpenSessionListProps> = () => {
  const listContext = useContext(ListContext);
  const openItems = listContext.items.filter((i) => i.status === "open");
  return (
    <ul className="mt-23">
      {openItems.map((item) => (
        <ListItem key={item.id} item={item} className="bg-indigo-100" />
      ))}
    </ul>
  );
};

export default OpenSessionList;
