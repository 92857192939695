import { collection, deleteDoc, doc, getDoc, getDocs, getFirestore, query, setDoc, where } from "firebase/firestore";
import { orderBy } from "lodash/fp";
import { Group, User } from "../types/user";


export const loadGroups = async (u: User): Promise<Array<Group>> => {
  const db = getFirestore();
  const colRef = collection(db, "Groups");

  const q = query(colRef, where("userEmails", "array-contains", u.id));

  const querySnapshot = await getDocs(q);
  const res: Array<Group> = [];
  querySnapshot.forEach((doc) => {
    res.push(doc.data() as Group);
  });
  return orderBy(g => g.createdDate, 'desc', res);
}

export const saveGroup = async (Group: Group) => {
  const db = getFirestore();
  await setDoc(doc(db, "Groups", Group.id), Group);
}

// need to check permission
export const loadGroup = async (id: string): Promise<Group> => {
  const db = getFirestore();
  const docRef = doc(db, "Groups", id);
  const docSnap = await getDoc(docRef);
  return docSnap.data() as Group;
}

export const removeGroup = async (id: string): Promise<void> => {
  const db = getFirestore();
  const docRef = doc(db, "Groups", id);
  return deleteDoc(docRef)
}