import { doc, getDoc, getFirestore, onSnapshot } from "firebase/firestore";


export type Configs = {
  version: string;
}

export const loadConfigs = async (id: string): Promise<Configs> => {
  const db = getFirestore();
  const docRef = doc(db, "Configs", id);
  const docSnap = await getDoc(docRef);
  return docSnap.data() as Configs;
}

export const subscribeConfigs = (id: string, subFn: (input: Configs) => void) => {
  const db = getFirestore();
  const docRef = doc(db, "Configs", id);
  const unsub = onSnapshot(docRef, (doc) => {
    subFn(doc.data() as Configs);
  });
  return unsub;
}
