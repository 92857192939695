import { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import Layout from "../components/Layout";

interface WaitConfirmationProps { }

const WaitConfirmation: FunctionComponent<WaitConfirmationProps> = () => {
  return (
    <Layout>
      <div className="h-screen flex flex-col items-center justify-center">
        <h1 className="text-2xl text-indigo-600">Congratulation!</h1>
        <p>It is now almost done</p>
        <div className="text-left px-4 mt-4">
          <p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 inline-block text-indigo-600"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
              />
            </svg>{" "}
            A{" "}
            <span className="underline text-indigo-600 font-bold">
              login link
            </span>{" "}
            is sent to your <span className="">email</span>
          </p>
          <p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 inline-block text-indigo-600"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
              />
            </svg>{" "}
            Now you should{" "}
            <span className="text-indigo-600 font-bold underline">
              check your email
            </span>
          </p>
          <p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 inline-block text-indigo-600"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
              />
            </svg>{" "}
            And you can{" "}
            <span className="text-indigo-600 font-bold underline">
              close this tab
            </span>
          </p>
          <div className="flex justify-between mt-4">
            <Link className="underline" to="/list">Home</Link>
            <Link className="underline" to="/login">Back to login page</Link>
          </div>
        </div>

      </div>
    </Layout>
  );
};

export default WaitConfirmation;
