import React, { FunctionComponent, useContext, useState } from "react";
import { groupBy, orderBy, get } from "lodash/fp";

import AuthenticatedLayout from "../components/AuthenticatedLayout";
import ExpenseRow from "../components/Session/ExpenseRow";
import SessionContext from "../context/SessionContext";
import { TabContainer, TabHeader, TabContent } from "../components/Tab";
import { formatFriendlyDateTimeWithoutDay } from "../utils/dateUtils";
import Summary from "../components/Session/Summary";
import { Expense } from "../types/session";
import { doIHavePermission, getUser, sumExpenses } from "../utils/sessionUtils";
import AppContext from "../context/AppContext";
import dayjs from "dayjs";
import ViewSettings, {
  ViewSettingsConfig,
} from "../components/Session/ViewSettings";
import SessionInfo from "../components/Session/SessionInfo";
import CheckBoxField from "../components/CheckBoxField";
import Popup from "../components/Popup";
import RightMenuBottom from "../components/Session/RightMenuButton";
import BottomButton from "../components/BottomButton";
import { ReactComponent as AdjustmentsIcon } from "../components/icons/adjustments.svg";
import { ReactComponent as ReceiptTaxIcon } from "../components/icons/receipt-tax.svg";
import { Link, useParams } from "react-router-dom";

interface SessionDetailProps {}

const SessionDetail: FunctionComponent<SessionDetailProps> = () => {
  const [groupedByDate, setGroupedByDate] = useState(true);
  const [viewSettings, setViewSettings] = useState<ViewSettingsConfig>({
    sharedBy: "",
    paidBy: "",
  });
  const [showViewSettings, setShowViewSettings] = useState(false);
  const params = useParams<{ id: string }>();
  const { session } = useContext(SessionContext);
  const appContext = useContext(AppContext);
  const filteredExpenses = (session?.expenses || []).filter((s) => {
    let output = true;
    if (viewSettings.paidBy) {
      output =
        output &&
        (s.paidBy.email === viewSettings.paidBy ||
          s.paidBy.id === viewSettings.paidBy);
    }
    if (viewSettings.sharedBy) {
      output =
        output &&
        s.sharedBy.some(
          (s) =>
            s.email === viewSettings.sharedBy || s.id === viewSettings.sharedBy
        );
    }
    return output;
  });
  const groupedExpenses = orderBy(
    ([g]) => new Date(g),
    "desc",
    Object.entries(
      groupBy(
        (s: Expense) =>
          s.when
            ? formatFriendlyDateTimeWithoutDay(s.when)
            : formatFriendlyDateTimeWithoutDay(new Date().toISOString()),
        filteredExpenses
      )
    )
  );
  const onGroupedByDateCheckChanged: React.ChangeEventHandler<
    HTMLInputElement
  > = (e) => {
    setGroupedByDate(e.target.checked);
  };
  const hasPermissions = doIHavePermission(
    appContext.user,
    session?.sharedBy,
    session?.createdBy
  );
  const readOnly = !hasPermissions || session?.status === "done";
  const toggleViewSettings = () => setShowViewSettings(!showViewSettings);
  const onViewSettingsChanged = (field: string, value: string) => {
    setViewSettings({ ...viewSettings, [field]: value });
  };
  const onFilterByMeChanged =
    (field: string): React.ChangeEventHandler<HTMLInputElement> =>
    (e) => {
      setViewSettings({
        ...viewSettings,
        [field]: e.target.checked ? appContext.user?.email : "",
      });
    };
  const filterByMe =
    viewSettings.paidBy === appContext.user?.email ||
    viewSettings.sharedBy === appContext.user?.email;
  const haveSettings = viewSettings.paidBy || viewSettings.sharedBy;
  const resetViewSettings = () => setViewSettings({ sharedBy: "", paidBy: "" });
  return (
    <AuthenticatedLayout
      renderRightButton={() => (
        <RightMenuBottom hasPermissions={hasPermissions} readOnly={readOnly} />
      )}
      renderBottomButton={() =>
        !readOnly && (
          <BottomButton
            link={`/session/${session?.id}/new-expense`}
            linkText="Expense"
          />
        )
      }
      backUrl="/list"
      showTopTitle
      pageTitle="Session Detail"
    >
      <SessionInfo readOnly={readOnly} />
      <div className="text-left mt-4 px-4 bg-white py-4 pb-8">
        <TabContainer
          tabHeader={
            <>
              <TabHeader index={0}>Detail</TabHeader>
              <TabHeader index={1}>Summary</TabHeader>
            </>
          }
        >
          <TabContent index={0}>
            <div className="py-2 flex justify-between items-center relative">
              <div className="flex items-center">
                Group by:
                <CheckBoxField
                  onChange={onGroupedByDateCheckChanged}
                  type="checkbox"
                  id="ckb_GroupByDate"
                  checked={groupedByDate}
                  className="ml-1"
                />{" "}
                <label htmlFor="ckb_GroupByDate">Date</label>
              </div>
              <div className="relative">
                Edit view
                <button
                  onClick={toggleViewSettings}
                  className="text-indigo-500 inline-block bg-gray-100 p-1 ml-1"
                >
                  <AdjustmentsIcon className="h-6 w-6 inline-block" />
                </button>
              </div>
              <Popup
                visible={showViewSettings}
                onBackDropClicked={toggleViewSettings}
              >
                <ViewSettings
                  onViewSettingsChanged={onViewSettingsChanged}
                  settings={viewSettings}
                  onClosed={toggleViewSettings}
                  onClearClicked={resetViewSettings}
                />
              </Popup>
            </div>
            <div className="border-l-4 border-indigo-500 pl-1 py-2 bg-gray-200">
              View{" "}
              {(!haveSettings || filterByMe) && (
                <>
                  standard
                  <div className="flex items-center mt-2">
                    <CheckBoxField
                      id="ckb_paidByMe"
                      checked={viewSettings.paidBy === appContext.user?.email}
                      type="checkbox"
                      className=""
                      onChange={onFilterByMeChanged("paidBy")}
                    />
                    <label htmlFor="ckb_paidByMe" className="font-bold mr-2">
                      Paid by me
                    </label>
                    <CheckBoxField
                      id="ckb_sharedWithMe"
                      checked={viewSettings.sharedBy === appContext.user?.email}
                      className="ml-1"
                      type="checkbox"
                      onChange={onFilterByMeChanged("sharedBy")}
                    />
                    <label htmlFor="ckb_sharedWithMe" className="font-bold">
                      Shared with me
                    </label>
                  </div>
                </>
              )}
              {viewSettings.sharedBy && !filterByMe && (
                <div>
                  Shared By:{" "}
                  <span className="font-bold">
                    {getUser(viewSettings.sharedBy, session?.sharedBy)?.name}
                  </span>
                </div>
              )}
              {viewSettings.paidBy && !filterByMe && (
                <div>
                  Paid By:{" "}
                  <span className="font-bold">
                    {getUser(viewSettings.paidBy, session?.sharedBy)?.name}
                  </span>
                </div>
              )}
            </div>
            {groupedExpenses.map(([key, values], index) => (
              <div className="" key={key || index}>
                {groupedByDate && (
                  <h5 className="font-bold mt-2 py-1 justify-between flex">
                    {dayjs(key).format("dddd, YYYY-MM-DD")}
                    <span>
                      Total: {sumExpenses(values)}{" "}
                      {session?.currency || get("[0].currency", values)}
                    </span>
                  </h5>
                )}
                {values.map((expense) => (
                  <React.Fragment key={expense.id}>
                    <hr />
                    <ExpenseRow readOnly={readOnly} data={expense} />
                  </React.Fragment>
                ))}
              </div>
            ))}
            {!groupedExpenses.length && !readOnly && (
              <div>
                <Link
                  to={`/session/${params.id}/new-expense`}
                  className="text-white bg-indigo-600 px-2 py-1 mt-4 flex inline-flex items-center"
                >
                  Add expense
                  <ReceiptTaxIcon />
                </Link>
              </div>
            )}
          </TabContent>
          <TabContent index={1}>
            <Summary />
          </TabContent>
        </TabContainer>
      </div>
    </AuthenticatedLayout>
  );
};

export default SessionDetail;
