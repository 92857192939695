import { FunctionComponent } from "react";
import FormRow from "./FormRow";
import SelectField, { SelectFieldProps } from "./SelectField";

interface FormSelectRowProps {
  label: string;
  value?: string | number;
  selectFieldProps?: SelectFieldProps;
  name?: string;
  onChange?: React.ChangeEventHandler<HTMLSelectElement>;
  className?: string;
  children?: React.ReactNode;
}

const FormSelectRow: FunctionComponent<FormSelectRowProps> = ({
  label,
  value,
  selectFieldProps,
  name,
  onChange,
  className,
  children,
}) => {
  return (
    <FormRow label={label} className={className}>
      <SelectField
        {...selectFieldProps}
        name={name}
        onChange={onChange}
        value={value}
        items={selectFieldProps?.items || []}
      />

      {children}
    </FormRow>
  );
};

export default FormSelectRow;
