import { FunctionComponent } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";

import Layout from "../components/Layout";

import TextField from "../components/TextField";
import Button from "../components/Button";
import useAppContext from "../hooks/useAppContext";

interface LoginProps {}

const LoginSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Required"),
});

const Login: FunctionComponent<LoginProps> = () => {
  const navigate = useNavigate();
  const appContext = useAppContext();
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validateOnBlur: true,
    validateOnChange: false,
    validationSchema: LoginSchema,
    onSubmit: (values) => {
      formik.validateForm();
      if (formik.isValid) {
        appContext.sendMagicLink(values.email).then(() => {
          navigate("/waitconfirmation");
        });
        //navigate("/list");
      }
    },
  });
  const onGoogleLoginClicked = () => {
    appContext.loginViaGoogle().then((u) => {
      navigate("/list");
    });
  };
  return (
    <Layout>
      <form
        className="flex flex-col pt-10 px-4 text-left"
        onSubmit={formik.handleSubmit}
      >
        <div className="login-form">
          <h1 className="mt-10 uppercase text-2xl font-bold">Splittaxpens</h1>
          <p className="italic text-gray-600">Share bills easily</p>
          <p className="mt-10">
            <span className="border-b-2 border-indigo-600">
              Getting started by
            </span>
          </p>
          <div className="mt-4">
            <button
              onClick={onGoogleLoginClicked}
              type="button"
              className="block border w-full py-2 border-gray-300 bg-white"
            >
              Sign in with <span className="font-bold">Google</span>
            </button>
            <div className="flex items-center">
              <span className="px-4 text-gray-600">Or by email</span>
              <hr className="my-8 border-t-2 flex-1" />
            </div>
            <div className="w-full">
              <TextField
                value={formik.values.email}
                onChange={formik.handleChange}
                type="email"
                placeholder="Enter email to start"
                name="email"
                className="!border-solid border-gray-300"
              />
              {formik.errors.email && (
                <div className="text-right text-sm text-red-600 mt-1">
                  {formik.errors.email}
                </div>
              )}
            </div>
            <div className="mt-4 flex justify-center">
              <Button type="submit">Ok, ready</Button>
            </div>
          </div>
          <p className="text-center mt-8 text-indigo-600">
            <a target="_blank" href="/getting-started">
              Learn more about SplittaExpens
            </a>
          </p>
        </div>
      </form>
    </Layout>
  );
};

export default Login;
