import { FunctionComponent, useEffect } from "react";
import useAppContext from "../hooks/useAppContext";

interface NotificationProps {}

const Notification: FunctionComponent<NotificationProps> = () => {
  const appContext = useAppContext();
  const showNotification = !!appContext.notification;
  useEffect(() => {
    if (showNotification) {
      setTimeout(appContext.hideNotification, 1000);
    }
  }, [showNotification]);
  return (
    <div
      className={`transition-opacity duration-300 bg-white p-4 mt-2 shadow-lg flex ${
        showNotification ? "opacity-1" : "opacity-0"
      }`}
    >
      <div>
        {appContext.notification?.level === "success" && (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6 text-green-500"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        )}
        {appContext.notification?.level === "error" && (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6 text-red-500"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        )}
      </div>
      <div className="flex-1 ml-2">
        <h4 className="font-bold">{appContext.notification?.title}</h4>
        <p className="text-sm text-black/[0.7] mt-1">
          {appContext.notification?.message}
        </p>
      </div>
    </div>
  );
};

export default Notification;
