import { FunctionComponent } from "react";
import { checkboxFieldBaseStyles } from "./formStyles";

interface CheckBoxFieldProps extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>
{

}

const CheckBoxField: FunctionComponent<CheckBoxFieldProps> = ({ className, ...props }) => {
  return (
    <input
      {...props}
      className={`${checkboxFieldBaseStyles} ${className}`}
      type="checkbox"      
  />
  );
}

export default CheckBoxField;