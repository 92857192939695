const debounceFunction = (
  // eslint-disable-next-line @typescript-eslint/ban-types
  fn: Function,
  debouncedToken: React.MutableRefObject<any>,
  debouncedTime: number | undefined = 200
) => {
  return (...args: any) => {
    return new Promise((resolve, reject) => {
      if (debouncedToken.current) {
        clearTimeout(debouncedToken.current);
      }
      // eslint-disable-next-line no-param-reassign
      debouncedToken.current = setTimeout(
        () =>
          fn(...args)
            .then(resolve)
            .catch(reject),
        debouncedTime
      );
    });
  };
};
export { debounceFunction };
