import * as Yup from "yup";
import { useFormik } from "formik";
import { FunctionComponent } from "react";
import useAppContext from "../../hooks/useAppContext";
import { Group } from "../../types/user";
import { formatFriendlyDateTimeWithoutDay } from "../../utils/dateUtils";
import { generateId } from "../../utils/idUtils";
import FormTextRow from "../FormTextRow";
import ErrorFeedback from "../ErrorFeedback";
import Notification from "../Notification";
import FormRow from "../FormRow";
import { SessionUser } from "../../types/session";
import UserSelectList from "../UserSelectList";
import { sortUsers } from "../../utils/sessionUtils";
import Button from "../Button";
import { uniqBy } from "lodash/fp";

interface GroupFormProps {
  data?: Group;
  onCancelClicked: () => void;
  onSaveClicked: (input: Group) => Promise<void>;
  onRemoveClicked: () => Promise<void>;
}

const GroupSchema = Yup.object().shape({
  name: Yup.string().required("Required"),
  users: Yup.array().required("Users is required"),
});

const GroupForm: FunctionComponent<GroupFormProps> = ({ data, onCancelClicked, onSaveClicked, onRemoveClicked }) => {
  const appContext = useAppContext();
  const today = new Date();
  const currentDate = formatFriendlyDateTimeWithoutDay(today.toString());
  const defaultValue: Group = {
    id: generateId(),
    name: "",
    isDefault: false,
    users: [],
    userEmails: [],
    createdBy: appContext.user!.email,
    lastUpdatedBy: appContext.user!.email,
    createdDate: currentDate,
    lastUpdatedDate: currentDate,
  };
  const editMode = Boolean(data);
  const formik = useFormik<Group>({
    initialValues: data || defaultValue,
    validateOnBlur: true,
    validateOnChange: false,
    validationSchema: GroupSchema,
    onSubmit: (values) => {
      values.userEmails = values.users.map(u => u.email).filter(email => !!email);
      return onSaveClicked(values);
    },
  });
  const onCheckedChange =
    (field: string): React.ChangeEventHandler<HTMLInputElement> =>
      (e) => {
        formik.setFieldValue(field, e.target.checked);
      };
  const onUsersChanged = (value: Array<SessionUser>) => {
    formik.setFieldValue("users", value);
  };
  const currentSessionUser: SessionUser = {
    name: appContext.user!.name || "",
    email: appContext.user!.email,
    id: appContext.user!.id,
    isGroup: false,
  };
  const items = uniqBy(u => u.email || u.id, [currentSessionUser, ...appContext.user?.contacts!]);
  const onUserCheckChanged: React.ChangeEventHandler<HTMLInputElement> = (
    e
  ) => {
    if (!e.target.checked)
      onUsersChanged(formik.values.users.filter((v) => v.id !== e.target.value));
    else {
      const item = items.find((i) => i.id === e.target.value);
      formik.values.users.push(item!);
      onUsersChanged(sortUsers(formik.values.users));
    }
  };

  const onUserUpdated = (user: SessionUser) => {
    const index = formik.values.users.findIndex((i) => i.id === user.id);
    if (index > -1) {
      formik.values.users.splice(index, 1, user);
      onUsersChanged([...formik.values.users]);
    }
  }
  const onSelectAllClicked = () => {
    onUsersChanged(items);
  };

  const onClearAllClicked = () => {
    onUsersChanged([]);
  };

  const errors = Object.entries(formik.errors);
  return (
    <form onSubmit={formik.handleSubmit}>
      <h4 className="border-b border-black/[0.7] pb-1 mb-2 uppercase text-black/[0.7]">
        {editMode ? "Edit group" : "Add new group"}
      </h4>
      <p className="text-gray-600">Groups can be selected in Sessions</p>
      <FormTextRow
        name="name"
        label="Name"
        onChange={formik.handleChange}
        value={formik.values.name}
        textFieldProps={{
          className: "!bg-gray-100",
        }}
      />
      <FormRow label="Default group" className="mt-2">
        <input
          id="ckb-isDefault"
          onChange={onCheckedChange("isDefault")}
          name="isDefault"
          type="checkbox"
          checked={Boolean(formik.values.isDefault)}
        />

      </FormRow>
      <div style={{
        maxHeight: '40vh'
      }} className="mt-4 px-4 overflow-y-scroll">
        <label className="uppercase text-black/[0.7] block font-semibold py-2">Users in group</label>
        <UserSelectList
          onClearAllClicked={onClearAllClicked}
          onSelectAllClicked={onSelectAllClicked}
          onUserCheckChanged={onUserCheckChanged}
          onUserChanged={onUserUpdated}
          items={items}
          value={formik.values.users}
          showEditTotalInGroup
          key="users"
          name="users"
        />
      </div>
      <div className="flex justify-center">
        <Button type="submit" className="mt-2 px-4">
          Save
        </Button>
        {editMode && <Button type="button" onClick={onRemoveClicked} className="mt-2 ml-2 px-4 bg-red-500">
          Remove
        </Button>}
        <Button type="button" onClick={onCancelClicked} className="mt-2 ml-2 px-4 bg-red-500">
          Cancel
        </Button>
      </div>
      <Notification />
      {Boolean(errors.length) && <ErrorFeedback errors={errors} />}
    </form>
  );
};

export default GroupForm;
