import * as UserRepository from "../repositories/UserRepository";

const mapping = new Map();

export const getAvatar = async (userEmail: string): Promise<string> => {  
  if(mapping.has(userEmail)) return await mapping.get(userEmail);
  try {
    const avtRes = UserRepository.getAvatar(userEmail);
    mapping.set(userEmail, avtRes);  
    return avtRes.then(avt => {
      mapping.set(userEmail, avt);  
      return avt;
    })
  } catch (error) {
    mapping.set(userEmail, "");
    return "";
  }
  
}

export const setAvatar = (userEmail: string, url: string) => {
  mapping.set(userEmail, url);
}