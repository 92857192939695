import { uniqBy } from "lodash/fp";
import { FunctionComponent, useState } from "react";
import useAppContext from "../../hooks/useAppContext";
import { Session, SessionUser } from "../../types/session";
import { User } from "../../types/user";
import { ReactComponent as RefreshIcon } from "../icons/refresh.svg";

interface SyncContactBlockProps {
  sessionToSync?: Session;
}

const getUsersToSync = (users?: Array<SessionUser>, user?: User) => {
  if (!user || !users || !users.length) return [];
  return users.filter(
    (u) =>
      u.email &&
      u.email !== user.email &&
      user.contacts.findIndex((c) => c.email === u.email || c.id === u.id) === -1
  );
};

const SyncContactBlock: FunctionComponent<SyncContactBlockProps> = ({
  sessionToSync,
}) => {
  const appContext = useAppContext();
  const [isLoading, setIsLoading] = useState(false);
  const usersList = uniqBy(u => u.email || u.id, ([] as Array<SessionUser>).concat(sessionToSync?.sharedBy || [], appContext.groups[0]?.users || []));
  const usersToSync = getUsersToSync(usersList, appContext.user);

  const onSyncClicked = async () => {
    setIsLoading(true);
    try {
      await appContext.syncContactsToUser(usersToSync);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <div className="flex w-full py-4 items-center">
      <div className="flex-1">
        <span
          className={`block border-b-2 font-bold inline-block border-green-600`}
        >
          Sync contacts
        </span>
        <p className="text-sm italic text-black/[0.7]">
          You have {usersToSync.length === 0 ? "no" : usersToSync.length}{" "}
          contacts to sync
        </p>
      </div>

      {!!usersToSync.length &&
        <div className="relative inline-flex">
          <button
            type="button"
            className="border p-1 ml-2 rounded-lg border-black/[0.5] text-black/[0.5]"
            onClick={onSyncClicked}
          >
            <RefreshIcon
              className={`text-green-600 w-6 h-6 ${isLoading && "animate-spin"}`}
            />
          </button>
          <span className="flex absolute h-3 w-3 top-0 right-0 -mt-1 -mr-1">
            <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-sky-400 opacity-75"></span>
            <span className="relative inline-flex rounded-full h-3 w-3 bg-green-500"></span>
          </span>

        </div>}
    </div>
  );
};

export default SyncContactBlock;
