import { FunctionComponent, useState } from "react";

interface AsyncButtonProps extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  text: string;
  doneText: string;
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => Promise<any>
}

const AsyncButton: FunctionComponent<AsyncButtonProps> = ({ onClick, text, doneText, children, ...props }) => {
  const [textToShow, setTextToShow] = useState(text);
  const onClicked: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    onClick(e).then(() => {
      setTextToShow(doneText);
      setTimeout(() => {
        setTextToShow(text);
      }, 500);
    });
  }
  return (<button
    onClick={onClicked}
    {...props}
  >
    {children}
    {textToShow}
  </button>);
}

export default AsyncButton;