import { FunctionComponent } from "react";

interface LayoutProps {
  children: React.ReactNode;
}

const Layout: FunctionComponent<LayoutProps> = ({ children }) => {
  return (<div className="container h-screen m-auto font-sans bg-gray-100">{children}</div>);
}

export default Layout;