import { FunctionComponent } from "react";
import { textFieldBaseStyles } from "./formStyles";

export interface SelectFieldProps
  extends React.DetailedHTMLProps<
  React.SelectHTMLAttributes<HTMLSelectElement>,
  HTMLSelectElement
  > {
  items: Array<{ label: string, value: string }>
}

const SelectField: FunctionComponent<SelectFieldProps> = ({ items, className, ...props }) => {
  return (
    <select {...props} className={`${textFieldBaseStyles} ${className}`}>
      {items.map(item => (
        <option key={item.value} value={item.value}>{item.label}</option>
      ))}
    </select>
  );
};

export default SelectField;
