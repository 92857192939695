import React from 'react';

export interface ITabContext {
  current: number,
  changeTab: (tab: number) => void;
}

export default React.createContext<ITabContext>({
  current: 0,
  changeTab: () => null
});
