import { useFormik } from "formik";
import * as Yup from "yup";

import { FunctionComponent, useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AuthenticatedLayout from "../components/AuthenticatedLayout";
import FormTextrAreaRow from "../components/FormTextrAreaRow";
import FormTextRow from "../components/FormTextRow";
import Popup from "../components/Popup";
import SessionContext from "../context/SessionContext";
import { ReactComponent as Check } from "../components/icons/check.svg";
import { SessionUser, ShoppingItem } from "../types/session";
import { generateId } from "../utils/idUtils";
import SessionUserSelect from "../SessionUserSelect";
import FormRow from "../components/FormRow";
import { ReactComponent as ExternalLink } from "../components/icons/external-link.svg";
import { ReactComponent as DocumentAdd } from "../components/icons/document-add.svg";
import SlideButton from "../components/SlideButton";
import CheckBoxField from "../components/CheckBoxField";
import useAppContext from "../hooks/useAppContext";
import BottomButton from "../components/BottomButton";
import ErrorFeedback from "../components/ErrorFeedback";

interface ShopingListProps { }

const ShoppingSchema = Yup.object().shape({
  name: Yup.string().required("Required"),
  link: Yup.string().url('Link must be https:// or http://')
});


const ShopingList: FunctionComponent<ShopingListProps> = () => {
  const params = useParams<{ id: string }>();
  const sessionContext = useContext(SessionContext);
  const [showDialog, setShowDialog] = useState(false);
  const [assignedToMe, setAssignedToMe] = useState(false);
  const onAddNewClicked = () => {
    setShowDialog(!showDialog);
    setIsEditMode(false);
    if (!showDialog) {
      formik.values.id = generateId();
    }
  };
  const [isEditMode, setIsEditMode] = useState(false);
  const formik = useFormik<ShoppingItem>({
    initialValues: {
      id: generateId(),
      name: "",
      quantity: 1,
      status: "assigned",
      link: "",
      note: "",
    },
    validationSchema: ShoppingSchema,
    validateOnBlur: true,
    validateOnChange: false,
    onSubmit: (values) => {
      formik.validateForm();
      if (formik.isValid) {
        if (isEditMode) {
          sessionContext.saveShoppingItem(formik.values);
          onCloseClicked();
        } else {
          sessionContext.addShoppingItem(formik.values);
          onCloseClicked();
        }
      }
    },
  });
  const onCloseClicked = () => {
    setShowDialog(false);
    formik.resetForm();
  };

  const onRemoveClicked = (item: ShoppingItem) => () => {
    sessionContext.removeShoppingItem(item).then(() => {
      onCloseClicked();
    });
  };
  const onEditClicked = (input: ShoppingItem) => () => {
    setShowDialog(true);
    setIsEditMode(true);
    formik.setValues(input);
  };

  const onAssignedToChanged = (value: Array<SessionUser>) => {
    const assignedTo = value.pop();
    formik.setFieldValue("assignedTo", assignedTo);
  };

  const onAssignedToMeChanged: React.ChangeEventHandler<HTMLInputElement> = (
    e
  ) => {
    setAssignedToMe(e.target.checked);
  };

  const appContext = useAppContext();
  const filteredItems = assignedToMe
    ? (sessionContext.shoppingList?.items || []).filter(
      (a) => a.assignedTo?.email === appContext.user?.email
    )
    : sessionContext.shoppingList?.items;
  const navigate = useNavigate();
  const onDoneClicked = (item: ShoppingItem) => () => {
    item.status = 'done';
    sessionContext.saveShoppingItem(item).then(() => {
      navigate(`/session/${sessionContext.session?.id}/new-expense`, {
        state: [item],
      });
    })

  };
  const onDoneAllClicked = () => {
    filteredItems?.forEach(item => {
      item.status = 'done'
    })
    sessionContext.saveShoppingItems(filteredItems || []).then(() => {
      navigate(`/session/${sessionContext.session?.id}/new-expense`, {
        state: filteredItems,
      });
    })

  };
  const readOnly = false;
  const errors = Object.entries(formik.errors);

  return (
    <AuthenticatedLayout
      showTopTitle
      pageTitle="Shopping List"
      backUrl={`/session/${params.id}`}
      renderBottomButton={() =>
        !readOnly && (
          <BottomButton
            link={`/session/${sessionContext.session?.id}/new-expense`}
            linkText="Expense"
          />
        )
      }
    >
      <h1 className="px-4 mt-4 text-left text-sm font-bold uppercase">
        {sessionContext.session?.name}
      </h1>
      <p className="px-4 text-left text-sm text-gray-500 mt-2">
        Shopping list helps to plan preparations before an event. When you are
        done with shopping assigned to you then an expense can be created from
        the list{" "}
      </p>
      <div className="border p-2 bg-white relative mt-4 mx-4 text-left text-sm pb-24">
        <div className="border-l-4 border-indigo-500 pl-1 py-2 bg-gray-200 flex items-center justify-between">
          <div>
            View
            <CheckBoxField
              id="ckb_assignedToMe"
              checked={assignedToMe}
              type="checkbox"
              className="ml-1"
              onChange={onAssignedToMeChanged}
            />
            <label htmlFor="ckb_assignedToMe" className="font-bold mr-2">
              Assigned to me
            </label>
          </div>
          {assignedToMe && !!filteredItems?.length && (
            <button
              onClick={onDoneAllClicked}
              className="text-indigo-600 font-semibold mr-2"
              type="button"
            >
              Done your list
            </button>
          )}
        </div>
        <ul>
          <li className="py-1 border-b border-gray-100 flex justify-between items-center font-semibold">
            <span className="w-6/12">Item</span>
            <span className="w-4/12">Assigned to</span>
            <span className="w-2/12"></span>
          </li>
          {(filteredItems || []).map((item) => (
            <li
              className={`py-1 border-b border-gray-100 flex justify-between items-center pl-2 ${item.status === 'done' && 'bg-green-500'}`}
              key={item.id}
            >
              <span className="w-6/12">
                <span className="block">{item.name}</span>
                <span>x{item.quantity === undefined ? 1 : item.quantity}</span>
              </span>
              <span className="w-4/12">{item.assignedTo?.name}</span>
              {item.link && (
                <a
                  href={item.link}
                  className="inline-block text-indigo-500"
                  target="_blank"
                  rel="noreferrer"
                >
                  <ExternalLink />
                </a>
              )}
              <SlideButton
                label={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M4 6h16M4 12h8m-8 6h16"
                    />
                  </svg>
                }
                labelClassName="pl-4"
                id={`ckb_shopping_menu_${item.id}`}
                popupClassName="!w-24"
              >
                {item.status !== 'done' && <button
                  type="button"
                  className="p-2 border-b w-full text-left"
                  onClick={onEditClicked(item)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5 inline-block mr-2"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                  </svg>
                  Edit
                </button>}
                {item.status !== 'done' && <button
                  type="button"
                  className="p-2 w-full text-left border-b"
                  onClick={onDoneClicked(item)}
                >
                  <Check className="h-5 w-5 inline-block text-green-500 mr-2" />
                  Done
                </button>}
                <button
                  onClick={onRemoveClicked(item)}
                  type="button"
                  className="p-2 w-full text-left text-red-500"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 inline-block" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                  Remove
                </button>
              </SlideButton>
            </li>
          ))}
          <li className="mt-2 text-right">
            <button
              onClick={onAddNewClicked}
              type="button"
              className="flex inline-flex items-center text-white border bg-indigo-500 p-1"
            >
              <DocumentAdd className="inline-block w-6 h-6" />
              New item
            </button>
          </li>
        </ul>
        <Popup onBackDropClicked={onCloseClicked} visible={showDialog}>
          <h4 className="text-xl mb-2 flex justify-between">Shopping Item</h4>
          <form onSubmit={formik.handleSubmit}>
            <FormTextRow
              label="Name"
              value={formik.values.name}
              onChange={formik.handleChange}
              name="name"
              className="border-t"
              autoSuggest
              textFieldProps={{
                className: "!bg-gray-100",
                placeholder: "What to buy?",
              }}
              onSuggestionClicked={(txt) => formik.setFieldValue("name", txt)}
            />
            <FormTextRow
              label="Quantity"
              value={
                formik.values.quantity === undefined ? 1 : formik.values.quantity
              }
              onChange={formik.handleChange}
              name="quantity"
              textFieldProps={{
                type: "number",
                inputMode: "decimal",
                className: "!bg-gray-100",
                placeholder: "How many?",
              }}
            />
            <FormRow label="Assigned to">
              <SessionUserSelect
                onChange={onAssignedToChanged}
                items={sessionContext.session?.sharedBy || []}
                value={formik.values.assignedTo ? [formik.values.assignedTo] : []}
                className="mt-2"
                key="assignedTo"
                name="assignedTo"
                tagsClassName="!bg-gray-100"
                isSingleChoice
              />
            </FormRow>
            <FormTextRow
              label="Link"
              value={formik.values.link}
              onChange={formik.handleChange}
              name="link"
              textFieldProps={{
                className: "!bg-gray-100",
                placeholder: "Link to the item",
              }}
            />
            <FormTextrAreaRow
              label="Note"
              value={formik.values.note || ""}
              onChange={formik.handleChange}
              name="note"
              textFieldAreaProps={{
                className: "!bg-gray-100",
              }}
            />
            <div className="mt-4">
              <button          
                className="bg-indigo-500 text-white px-4 py-1"
                type="submit"
              >
                Save
              </button>
              {isEditMode && (
                <button
                  onClick={onRemoveClicked(formik.values)}
                  type="button"
                  className="ml-1 bg-red-500 text-white px-4 py-1"
                >
                  Remove
                </button>
              )}
            </div>
            {Boolean(errors.length) && <ErrorFeedback errors={errors} />}
          </form>
        </Popup>
      </div>
    </AuthenticatedLayout>
  );
};

export default ShopingList;
