import React from 'react';
import { SessionUser } from '../types/session';
import { Group, User } from '../types/user';

export type Notification = {
  title: string;
  message: string;
  level: 'error' | 'info' | 'success',
}

export interface IAppContext {
  user?: User;
  sendMagicLink: (email: string) => Promise<void>;
  authenticateByMagicLink: (input: string) => Promise<User | null>;
  setUser: (user: User) => Promise<void | null>;
  addContact: (user: User, contact: SessionUser) => Promise<void | null>;
  saveContact: (user: User, contact: SessionUser) => Promise<void | null>;
  removeContact: (user: User, id: string) => Promise<void | null>;
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  notification?: Notification;
  showSuccessNotification: (title: string, message: string, onDone?: Function) => void;
  showErrorNotification: (title: string, message: string, onDone?: Function) => void;
  showInfoNotification: (title: string, message: string, onDone?: Function) => void;
  hideNotification: () => void;
  logOut: () => Promise<void>;
  login: (user: User) => void;
  loginViaGoogle: () => Promise<User | null>;
  latestVersion: string;
  currentVersion: string;
  syncContactsToUser: (usersToSync?: Array<SessionUser>) => Promise<void>;
  groups: Array<Group>;
  setGroups: React.Dispatch<React.SetStateAction<Group[]>>,
}

export default React.createContext<IAppContext>({
  sendMagicLink: () => Promise.resolve(),
  authenticateByMagicLink: () => Promise.resolve(null),
  setUser: () => Promise.resolve(),
  addContact: () => Promise.resolve(),
  saveContact: () => Promise.resolve(),
  removeContact: () => Promise.resolve(),  
  logOut: () => Promise.resolve(),  
  isLoading: false,
  setIsLoading: () => {},
  showSuccessNotification: () => {},
  showErrorNotification: () => {},
  showInfoNotification: () => {},
  hideNotification: () => {},  
  login: () => {},
  loginViaGoogle: () => Promise.resolve(null),
  syncContactsToUser: () => Promise.resolve(),
  latestVersion: '',
  currentVersion: '',
  groups: [],
  setGroups: () => {},
});
