import { orderBy } from "lodash/fp";
import { Expense, Report, Session, SessionUser } from "../types/session";
import { User } from "../types/user";
import { getUserKey } from "./idUtils";
import { formatNumber } from "./numberUtils";

export const doIHavePermission = (
  user?: User,
  sharedBy?: Array<SessionUser>,
  createdBy?: string
) =>
  user &&
  (user.email === createdBy ||
    (sharedBy || []).some((u) => u.email === user.email));

export const getTotalPersonsToShare = (users: Array<SessionUser>): number =>
  users.reduce((acc, curr) => {
    const totalInGroup = curr.isGroup ? curr.totalInGroup || 1 : 1;
    return acc + totalInGroup;
  }, 0);

export const getUser = (emailOrId: string, users?: Array<SessionUser>) =>
  (users || []).find((u) => u.email === emailOrId || u.id === emailOrId);

export const getReport = (session?: Session | null): Report => {
  const report: Report = {
    totalSpent: 0,
    totalUsersToShare: 0,
    details: new Map(),
  };
  if (!session) return report;
  const expenses = session.expenses || [];
  report.totalUsersToShare = getTotalPersonsToShare(session.sharedBy);
  return expenses.reduce((acc, curr) => {
    if(!curr.paidBy) return acc;
    const key = getUserKey(
      getUser(curr.paidBy.id, session.sharedBy),
      curr.paidBy.id
    )!;

    acc.details.set(key, {
      paid: (acc.details.get(key)?.paid || 0) + curr.amount,
      balance: (acc.details.get(key)?.balance || 0) + curr.amount,
      currency: session.currency,
      rate: session.rate,
    });
    acc.totalSpent += curr.amount;
    curr.sharedBy.forEach((u) => {
      const totalInGroup = u.isGroup ? u.totalInGroup || 1 : 1;
      const totalToShare = getTotalPersonsToShare(curr.sharedBy);
      const sharedByKey = getUserKey(getUser(u.id, session.sharedBy), u.id);
      if (sharedByKey) {
        acc.details.set(sharedByKey, {
          paid: acc.details.get(sharedByKey)?.paid || 0,
          balance:
            (acc.details.get(sharedByKey)?.balance ||
              0) - (totalInGroup * curr.amount) / totalToShare,
          currency: session.currency,
          rate: session.rate,
        });
      }
    });
    return acc;
  }, report);
};

export const sumExpenses = (expenses: Array<Expense>) =>
  formatNumber(expenses.reduce((acc, curr) => acc + curr.amount, 0));

export const roundAmount = (input: number, currency: string): number => {
  if (["usd", "eur", "gbp"].includes(currency))
    return Math.round(input * 100) / 100;
  return Math.round(input);
};

export const hasUserCustomSettings = (
  userInExpense: SessionUser,
  userInSession: SessionUser
): boolean => {
  return (
    !!userInExpense.isGroup !== !!userInSession.isGroup ||
    userInExpense.totalInGroup !== userInSession.totalInGroup
  );
};

export const hasCustomSettings = (
  usersInExpense: Array<SessionUser>,
  usersInSession: Array<SessionUser>
): boolean => {
  return usersInExpense.some((u) => {
    const according = getUser(u.id, usersInSession);
    return according && hasUserCustomSettings(u, according);
  });
};

export const sortUsers = (users?: Array<SessionUser>) =>
  orderBy((v) => v.name.toLowerCase(), "asc", users || []);
