import { FunctionComponent } from "react";

interface PopupProps {
  children?: React.ReactNode;
  visible?: boolean;
  onBackDropClicked?: React.MouseEventHandler<
    HTMLDivElement | HTMLButtonElement
  >;
}

const Popup: FunctionComponent<PopupProps> = ({
  children,
  onBackDropClicked,
  visible,
}) => {
  return (
    <div
      onClick={onBackDropClicked}
      className={`z-30 fixed bg-opacity-40 bg-gray-500 inset-0 ${
        visible ? "visible" : "invisible"
      }`}
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className={`duration-500 transition-all absolute w-full z-10 bottom-0 top-10 flex-1 ${
          visible ? "" : "translate-y-full"
        }`}
      >
        <div className="container relative m-auto">
          <button
            onClick={onBackDropClicked}
            className="absolute right-0 top-0 p-1"
            type="button"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </button>
        </div>
        <div
          style={{
            minHeight: "30vh",
          }}
          className="p-4 bg-white shadow-lg container m-auto rounded-tl-lg max-h-screen flex flex-col"
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default Popup;
