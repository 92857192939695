import { FunctionComponent, useEffect, useState } from "react";
import { useDetectClickOutside } from "react-detect-click-outside";

import { SessionUser } from "../types/session";
import { User } from "../types/user";
import { getColorForUser } from "../utils/colorUtils";
import { getShortName } from "../utils/idUtils";
import { getAvatar } from "../utils/userUtils";

interface UserTagProps {
  user: SessionUser | User;
  className?: string;
  showAvatar?: boolean; 
}

const UserTag: FunctionComponent<UserTagProps> = ({ user, className, showAvatar }) => {
  const [showToolTips, setShowToolTips] = useState(false);
  const toggleToolTips = () => setShowToolTips(!showToolTips);
  const ref = useDetectClickOutside({
    onTriggered: () => setShowToolTips(false),
  });
  const [photoUrl, setPhotoUrl] = useState("");
  useEffect(() => {
    if (user?.email && showAvatar) getAvatar(user.email).then(setPhotoUrl);
  }, [user?.email, showAvatar]);

  return (
    <div ref={ref} className="inline-block relative">
      <button
        className={`w-8 h-8 rounded-full text-sm font-bold text-white inline-block text-center leading-8 overflow-hidden ${
          !photoUrl ? getColorForUser(user) : 'bg-cover bg-no-repeat bg-center'
        } ${className}`}
        title={user.name || ''}
        type="button"
        onClick={toggleToolTips}
        style={{
          backgroundImage: photoUrl ? `url(${photoUrl})` : "",
        }}
      >
        {!photoUrl || !showAvatar ? getShortName(user?.name || ""): '\u00a0'}
      </button>
      <div
        className={`text-black z-10 absolute peer-checked:block bg-white px-2 py-2 w-20 shadow-md bg-gray-100 text-sm ${
          showToolTips ? "block" : "hidden"
        }`}
      >
        {user.name}
      </div>
    </div>
  );
};

export default UserTag;
