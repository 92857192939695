import { FunctionComponent, useRef, useState } from "react";
import { take } from 'lodash/fp';
import { getByIndex, search } from "../lib/search";
import FormRow from "./FormRow";
import TextField, { TextFieldProps } from "./TextField";
import { debounceFunction } from '../utils/debounceUtils';

interface FormTextRowProps {
  label?: string;
  name?: string;
  value?: string | number;
  defaultValue?: string | number;
  textFieldProps?: TextFieldProps;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  className?: string;
  suggestions?: Array<string>;
  onSuggestionClicked?: (input: string) => void;
  autoSuggest?: boolean;
}

const FormTextRow: FunctionComponent<FormTextRowProps> = ({
  label,
  name,
  className,
  value,
  defaultValue,
  textFieldProps,
  onChange,
  suggestions,
  autoSuggest,
  onSuggestionClicked
}) => {
  const [internalSuggestions, setInternalSuggestions] = useState<Array<string>>([]);
  const onSuggestionClickedGenerator = (txt: string) => () => {
    if (onSuggestionClicked) {
      onSuggestionClicked(txt);
    }
  };
  const debouncedSearchToken = useRef();

  const updateSuggestions = debounceFunction(async (text: string) => {
    const results = take(4, search(text));
    setInternalSuggestions((results || []).map(r => getByIndex(r.toString())!))
  }, debouncedSearchToken)
  const onTextChanged: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    if (autoSuggest) {
      updateSuggestions(e.target.value);
    }
    if (onChange)
      onChange(e);
  }
  const combinedSuggestions = ([] as Array<string>).concat(suggestions || [], internalSuggestions);
  return (
    <FormRow label={label} className={`${className} overflow-hidden`}>
      <div className="w-full">
        <TextField
          {...{ placeholder: label, ...textFieldProps }}
          onChange={onTextChanged}
          name={name}
          value={value}
          defaultValue={defaultValue}
        />
        {combinedSuggestions && !!combinedSuggestions.length && (
          <div className="w-full mt-1 flex overflow-x-scroll">
            {combinedSuggestions.map((sugg) => (
              <button
                key={sugg}
                onClick={onSuggestionClickedGenerator(sugg)}
                type="button"
                className="min-w-fit border px-1 text-gray-600 border-indigo-300 text-sm mr-1 text-sm rounded-full"
              >
                {sugg}
              </button>
            ))}
          </div>
        )}
      </div>
    </FormRow>
  );
};

export default FormTextRow;
