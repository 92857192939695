import { FunctionComponent } from "react";
import FormRow from "./FormRow";
import { textFieldBaseStyles } from "./formStyles";

interface FormDateTimeFieldProps {
  label: string;
  value?: string;
  onChange: (value: string) => void;
  className?: string;
}

const FormDateTimeField: FunctionComponent<FormDateTimeFieldProps> = ({
  className,
  label,
  value,
  onChange,
  ...props
}) => {
  return (
    <FormRow className={`${className} relative`}>
      <label className="text-sm text-black/[0.7] w-3/12">{label}</label>
      <input
        {...props}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        type="date"
        className={`${textFieldBaseStyles} date-field text-left`}
        id="datePicker"
      />
     
    </FormRow>
  );
};

export default FormDateTimeField;
