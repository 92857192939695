import { FunctionComponent } from "react";
import { Link } from "react-router-dom";

interface FunctionBlockProps {
  url: string;
  label: string;
  subText: string;
  className: string;
  icon?: React.ReactNode;
}

const FunctionBlock: FunctionComponent<FunctionBlockProps> = ({
  url,
  label,
  subText,
  className,
  icon,
}) => {
  return (
    <div className="flex w-full py-4 items-center">
      <div className="flex-1">
        <Link
          to={url}
          className={`block border-b-2 font-bold inline-block ${className}`}
        >
          {label}
        </Link>
        <p className="text-sm italic text-black/[0.7]">{subText}</p>
      </div>
      {
        icon && <Link to={url} className="border p-1 ml-2 rounded-lg border-black/[0.5] text-black/[0.5]">
          {icon}
        </Link>

      }
    </div>
  );
};

export default FunctionBlock;
