import { FunctionComponent, useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import Layout from '../components/Layout'
import useAppContext from "../hooks/useAppContext";

interface AuthenticateProps {

}

const Authenticate: FunctionComponent<AuthenticateProps> = () => {
  const appContext = useAppContext();
  const navigate = useNavigate();
  const [error, setError] = useState<Error>();
  useEffect(() => {
    const email = localStorage.getItem('authenticating')!; // will be replaced by mail
    if (localStorage.getItem('authenticating') === email) {
      appContext.authenticateByMagicLink(email).then((user) => {
        if (user) {
          appContext.login(user);
          navigate(user.name ? '/list' : '/settings')
        }
      })
    } else {
      setError(new Error(`Cannot authenticate ${email}`))
    }
  }, []);
  return (<Layout>{!error ? 'authenticating...' : error.message}</Layout>);
}

export default Authenticate;