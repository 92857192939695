import { SessionUser } from "../types/session";
import { User } from "../types/user";
import { getUserKey } from "./idUtils";

const colors = ['bg-indigo-500', 'bg-pink-500', 'bg-yellow-500', 'bg-amber-500', 'bg-sky-500', 'bg-slate-500', 'bg-lime-500', 'bg-teal-500']

export const getColor = (index: number) => {
  return colors[index % colors.length];
}

const mapping = new Map();

export const getColorForUser = (user: SessionUser | User): string => {
  const userKey = getUserKey(user);
  const inMapping = mapping.has(userKey);
  if (inMapping) return mapping.get(userKey) as string;
  const total = mapping.size;
  const color = colors[total % colors.length];
  mapping.set(userKey, color);
  return color;
}