import { FunctionComponent, useContext } from "react";
import { useNavigate, Link } from "react-router-dom";
import dayjs from "dayjs";
import isToday from 'dayjs/plugin/isToday';

import SessionContext from "../../context/SessionContext";
import { saveScrollPosition } from "../../hoc/withScrollPosition";
import { Expense, SessionUser } from "../../types/session";
import { getUser, sortUsers } from "../../utils/sessionUtils";
import UserSpan from "./UserSpan";
import { ReactComponent as ChevronRightIcon } from "../icons/chevron-right.svg";
import { formatNumber } from "../../utils/numberUtils";

dayjs.extend(isToday)

interface ExpenseRowProps {
  data: Expense;
  readOnly?: boolean;
}

const isUserValid = (user: SessionUser, users?: Array<SessionUser>) =>
  !!getUser(user?.id, users || []);

const ExpenseRow: FunctionComponent<ExpenseRowProps> = ({ data, readOnly }) => {
  const navigate = useNavigate();
  const sessionContext = useContext(SessionContext);
  const saveScrollPositionBeforeNavigating = () => {
    saveScrollPosition(window.location.pathname, window.scrollY);
  }
  const onRowClicked = () => {
    saveScrollPositionBeforeNavigating();
    navigate(`/session/${sessionContext.session!.id}/expense/${data.id}`);
  }
  return (
    <div className={`px-2 mt-2 py-2 ${dayjs(data.createdDate).isToday() && 'bg-indigo-100'} ${!data.paidBy && '!bg-orange-200'}`}>
      <div className="flex justify-between relative">
        <h4 className="w-full">
          {!readOnly ? (
            <Link
              to={`/session/${sessionContext.session!.id}/expense/${data.id}`}
              className="block"
              state={sessionContext.session}
              onClick={saveScrollPositionBeforeNavigating}
            >
              {data.name}
            </Link>
          ) : (
            data.name
          )}
        </h4>
        <div className="relative text-top flex">
          <span className="mr-1">{formatNumber(data.amount)}</span>
          <span className="text-black/[0.7] text-sm right-0">
            {data.currency}
          </span>
          <button
            onClick={onRowClicked}
            className="px-1 absolute right-0 top-8 z-10"
            type="button"
          >
            <ChevronRightIcon />
          </button>
        </div>
      </div>
      <div>
        <span className="text-black/[0.7] text-xs">Paid by:</span>{" "}
        <UserSpan
          user={data.paidBy}
          hideGroupInfo
          className={`${isUserValid(data.paidBy, sessionContext.session?.sharedBy)
            ? ""
            : "text-orange-600"
            }`}
        >
        </UserSpan>
      </div>
      <div className="relative">
        <span className="text-black/[0.7] text-xs">Shared by:</span>{" "}
        {sortUsers(data.sharedBy).map((s) => {
          const isValid = isUserValid(s, sessionContext.session?.sharedBy);
          return (
            <UserSpan
              key={s.id}
              user={s}
              className={`${isValid ? "" : "text-orange-600 bg-gray-100"
                }`}
            >
              {!isValid && (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 inline-block"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                  />
                </svg>
              )}
            </UserSpan>
          );
        })}
      </div>
    </div>
  );
};

export default ExpenseRow;
