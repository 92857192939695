import { FunctionComponent, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import SessionContext from "../../context/SessionContext";
import { Expense } from "../../types/session";
import { formatFriendlyDateTime } from "../../utils/dateUtils";
import { sortUsers } from "../../utils/sessionUtils";
import InfoRow from "./InfoRow";
import UserSpan from "./UserSpan";
import { ReactComponent as ShopingBag } from '../icons/shoping-bag.svg';
import { formatNumber } from "../../utils/numberUtils";

interface SessionInfoProps {
  readOnly: boolean;
}

const getTotal = (expenses: Array<Expense>) =>
  expenses.reduce((acc, ex) => acc + ex.amount, 0);

const SessionInfo: FunctionComponent<SessionInfoProps> = ({ readOnly }) => {
  const { session, shoppingList } = useContext(SessionContext);
  const params = useParams<{ id: string }>();
  const navigate = useNavigate();

  const onShoppingClicked = () => {
    navigate(`/session/${params.id}/shopping-list`);
  };


  const total = getTotal(session?.expenses || []);
  const currency = session?.currency || (session?.expenses || [])[0]?.currency;

  return (
    <div className="text-left py-2 bg-white overflow-x-hidden">
      <div className="text-sm text-black/[0.7] px-4 mt-2 flex items-center justify-between">
        <span className="h-5">
          {session?.status === "done" && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 inline-block"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                clipRule="evenodd"
              />
            </svg>
          )}
          {formatFriendlyDateTime(session?.when)}
        </span>
        <span className="relative w-6/12 flex inline-flex justify-end">
          {session?.status !== "done" && !readOnly && (
            <button type="button" onClick={onShoppingClicked} className="px-2">
              <ShopingBag className="w-5 h-5" />
              {!!shoppingList?.items.length && <span className="flex absolute h-3 w-3 top-0 right-0 -mt-1 -mr-1 z-10">
                <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-sky-400 opacity-75"></span>
                <span className="relative inline-flex rounded-full h-3 w-3 bg-sky-500"></span>
              </span>}
            </button>
          )}

        </span>
      </div>
      <h1 className="px-4 flex justify-between mt-1">
        <span className="text-xl">{session?.name}</span>
        {!!total && (
          <span className="ml-1">
            {formatNumber(total)}
            {currency}
          </span>
        )}
      </h1>

      <div className="px-4">
        {sortUsers(session?.sharedBy).map((p) => (
          <UserSpan user={p} key={p.id}></UserSpan>
        ))}
      </div>
      <hr className="mt-2" />
      <InfoRow label="Location" value={session?.location} />

      <hr className="mt-2" />
      <InfoRow label="Note" value={session?.note} />
    </div>
  );
};

export default SessionInfo;
