// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCAs024wWBXGtcUuu67bQtmmL9H4h07GRs",
  authDomain: "splitpayments-ba878.firebaseapp.com",
  projectId: "splitpayments-ba878",
  storageBucket: "splitpayments-ba878.appspot.com",
  messagingSenderId: "382956353808",
  appId: "1:382956353808:web:d0d4c4c051c3223d80cd95"
};


export const initFireBase = () => {
  const app = initializeApp(firebaseConfig);
  return app;
}