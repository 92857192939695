import React, { FunctionComponent } from "react";
import {
  Route,
  Routes as ReactRoutes,
  Outlet,
} from "react-router-dom";

import Settings from "./pages/Settings";
import Login from "./pages/Login";
import Listing from "./pages/Listing";
import SessionDetail from "./pages/SessionDetail";
import SessionForm from "./pages/SessionForm";
import ExpenseForm from "./pages/ExpenseForm";
import Authenticate from "./pages/Authenticate";
import WaitConfirmation from "./pages/WaitConfirmation";
import Contacts from "./pages/Contacts";
import withProtectedRoute from "./hoc/withProtectedRoute";
import SessionViewOnly from "./pages/SessionViewOnly";
import LandingPage from "./pages/LandingPage";
import NotFound from "./pages/NotFound";
import ShopingList from "./pages/ShopingList";
import SessionWrapper from "./components/SessionWrapper";
import withSessionContext from "./hoc/withSessionContext";

interface RoutesProps { }

interface LoginChallengeProps {
  children?: React.ReactNode;
}

const LoginChallenge: FunctionComponent<LoginChallengeProps> =
  withProtectedRoute(() => {
    return (
      <>
        <Outlet />
      </>
    );
  });

const NewSessionForm = withSessionContext(SessionForm);

const Routes: FunctionComponent<RoutesProps> = () => {
  return (<div className="container m-auto min-h-screen">
    <ReactRoutes>
      <Route path="/getting-started" element={<LandingPage />} />
      <Route path="/login" element={<Login />} />
      <Route path="/authenticate" element={<Authenticate />} />
      <Route path="/view/:id" element={<SessionViewOnly />} />
      <Route path="/waitconfirmation" element={<WaitConfirmation />} />
      <Route element={<LoginChallenge />}>
        <Route path="/settings" element={<Settings />} />
        <Route path="/" element={<Listing />} />
        <Route path="/list" element={<Listing />} />
        <Route path="/contacts" element={<Contacts />} />
        <Route element={<SessionWrapper />} path="/session">
          <Route path=":id" element={<SessionDetail />} />
          <Route path=":id/edit" element={<SessionForm />} />
          <Route path=":id/shopping-list" element={<ShopingList />} />
          <Route path=":id/new-expense" element={<ExpenseForm />} />
          <Route path=":id/expense/:expenseId" element={<ExpenseForm />} />

        </Route>             
        <Route path="/new-session" element={<NewSessionForm />} />
        <Route path="*" element={<NotFound />}></Route>
      </Route>
    </ReactRoutes>
  </div>
  );
};

export default Routes;
