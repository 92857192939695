import { FunctionComponent } from "react";

interface BlockHeaderProps {
  text: string;
  className?: string;
  children?: React.ReactNode;
  textClassName?: string;
}

const BlockHeader: FunctionComponent<BlockHeaderProps> = ({
  text,
  className,
  textClassName,
  children,
}) => {
  return (
    <h4 className={`text-white font-bold ${className}`}>
      <span className={`inline-block px-2 border-b-2 uppercase ${textClassName}`}>
        {text}
      </span>
      {children}
    </h4>
  );
};

export default BlockHeader;
