import { FunctionComponent, useRef } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useInViewport } from "react-in-viewport";
import withScrollPosition from "../hoc/withScrollPosition";
import useAppContext from "../hooks/useAppContext";
import BottomButton from "./BottomButton";
import { ReactComponent as SettingsIcon } from '../components/icons/cog.svg';
import UserTag from "./UserTag";


interface AuthenticatedLayoutProps {
  children: React.ReactNode;
  pageTitle: string;
  backUrl?: string;
  disableBackButton?: boolean;
  showTopTitle?: boolean;
  renderRightButton?: () => React.ReactNode;
  renderBottomButton?: () => React.ReactNode;
}

const AuthenticatedLayout: FunctionComponent<AuthenticatedLayoutProps> = ({
  children,
  pageTitle,
  backUrl,
  disableBackButton,
  showTopTitle,
  renderRightButton,
  renderBottomButton,
}) => {
  const navigate = useNavigate();
  const appContext = useAppContext();
  const myRef = useRef<HTMLHeadingElement | null>(null);
  const { inViewport } = useInViewport(myRef);

  const goBack = () => {
    if (backUrl) return navigate(backUrl);
    navigate(-1);
  };

  return (
    <div className="au-layout h-full bg-gray-100 font-sans flex flex-col min-h-screen">
      <div className="menu-top top-0 sticky bg-gray-100 text-black flex justify-between w-full items-center py-2 px-1 top-0 z-10">
        {disableBackButton ? (
          <span className="h-6 w-6"></span>
        ) : (
          <button onClick={goBack}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15 19l-7-7 7-7"
              />
            </svg>
          </button>
        )}
        <span className="uppercase font-bold text-sm inline-block">
          {showTopTitle || !inViewport ? pageTitle : ""}
        </span>
        {renderRightButton ? (
          renderRightButton()
        ) : (
          <Link to="/settings" className="inline-block relative">
            {appContext.user && <UserTag user={appContext.user} showAvatar />}
          </Link>
        )}
      </div>
      <div className="flex-1 pb-12">
        {!showTopTitle && (
          <h1 ref={myRef} className="text-left px-4 text-2xl mt-2">
            {pageTitle}
          </h1>
        )}
        {children}
      </div>
      <div className="fixed bottom-1 pr-1 text-right z-20  text-white container m-auto">
        {appContext.currentVersion &&
          appContext.currentVersion !== appContext.latestVersion && (
            <div className="relative flex justify-end">
              <span className="flex absolute h-3 w-3 top-0 right-0 -mt-1 -mr-1 z-10">
                <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-sky-400 opacity-75"></span>
                <span className="relative inline-flex rounded-full h-3 w-3 bg-sky-500"></span>
              </span>
              <button
                onClick={() => window.location.reload()}
                className="bg-black font-bold border border-gray-200 p-2 block rounded-lg relative mb-2"
              >
                New version available
              </button>
            </div>
          )}
        <div className="flex items-center inline-flex  bg-black rounded-lg">
          {renderBottomButton ? (
            renderBottomButton()
          ) : (
            <BottomButton link="/new-session" linkText="Session" />
          )}

          <label htmlFor="ckb_menu" className="px-4 py-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4 6h16M4 12h16m-7 6h7"
              />
            </svg>
          </label>
        </div>
        <input type="checkbox" className="peer hidden" id="ckb_menu" />
        <div className="absolute right-0 bottom-12 translate-x-full invisible transition-all peer-checked:visible peer-checked:translate-x-0">
          <ul className="w-40 bg-black rounded-lg mr-1">
            {[
              {
                label: "Getting started",
                link: "/getting-started",
              },
              {
                label: "Settings",
                link: "/settings",
              },
              {
                label: "Contacts",
                link: "/contacts",
              },
              {
                label: "Home",
                link: "/",
              },
            ].map((item) => (
              <li
                key={item.link}
                className="flex items-center flex-end py-2 pl-4 border-b font-bold last:border-none"
              >
                <Link className="block text-right" to={item.link}>
                  {item.label}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default withScrollPosition<AuthenticatedLayoutProps>(
  AuthenticatedLayout
);
