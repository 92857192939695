import { get } from "lodash/fp";
import { FunctionComponent, useContext } from "react";
import { Link } from "react-router-dom";
import ListContext from "../../context/ListContext";
import useAppContext from "../../hooks/useAppContext";
import { Session } from "../../types/session";
import { User } from "../../types/user";
import { formatFriendlyDateTime } from "../../utils/dateUtils";
import { getReport } from "../../utils/sessionUtils";

interface DetailBalancesProps {}

type DetailReport = {
  session: Session;
  amount: number;
};

const getReports = (
  user: User,
  sessions: Array<Session>
): Array<DetailReport> => {
  return sessions.reduce((acc, session) => {
    const report = getReport(session);
    if (report.details.has(user.email)) {
      acc.push({
        session,
        amount: report.details.get(user.email)?.balance || 0,
      });
    }
    return acc;
  }, [] as Array<DetailReport>);
};

const DetailBalances: FunctionComponent<DetailBalancesProps> = ({}) => {
  const appContext = useAppContext();
  const { items } = useContext(ListContext);
  const balances = getReports(
    appContext.user!,
    items.filter((i) => i.status === "open")
  );
  return (
    <div className="mt-4 text-left">
      <h2>Blance Details</h2>
      <ul>
        {balances.map((balance) => (
          <li className="flex border-t pt-2 mt-2" key={balance.session.id}>
            <div className="w-9/12">
              <Link to={`/session/${balance.session.id}`}>
                <h4 className="font-bold text-sm">{balance.session.name}</h4>
              </Link>
              <p className="text-sm text-black/[0.7]">
                {formatFriendlyDateTime(balance.session.when)}
              </p>
            </div>
            <div className="w-3/12 font-bold">
              {Math.round(balance.amount)}{" "}
              {get("session.expenses[0].currency", balance)}
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default DetailBalances;
