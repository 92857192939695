import { FunctionComponent } from "react";
import { useDetectClickOutside } from 'react-detect-click-outside';

interface SlideButtonProps {
  className?: string;
  id: string;
  labelClassName?: string;
  popupClassName?: string;
  label: string | React.ReactNode;
  ckbRef?: React.MutableRefObject<HTMLInputElement | null> ;
  children?: React.ReactNode;
}

const SlideButton: FunctionComponent<SlideButtonProps> = ({
  className,
  labelClassName,
  popupClassName,
  id,
  label,
  ckbRef,
  children,
}) => {
  const hidePopUp = () => {
    const ckb = document.getElementById(id) as HTMLInputElement;
    if(ckb) {
      ckb.checked = false;
    }
  }
  const ref = useDetectClickOutside({ onTriggered: hidePopUp });

  return (
    <div ref={ref} className={`inline-flex relative ${className}`}>
      <label role="button" className={labelClassName} htmlFor={id}>
        {label}
      </label>
      <div className={`absolute overflow-hidden right-1 top-8 mt-1 w-40 z-10 ${popupClassName}`}>
        <input
          type="checkbox"
          id={id}
          className="peer hidden"
          ref={ckbRef}
        />
        <div className="bg-gray-100 rounded-md transition-all translate-x-full peer-checked:translate-x-0 invisible peer-checked:visible h-0 peer-checked:h-auto">
          {children}
        </div>
      </div>
    </div>
  );
};

export default SlideButton;
