import { useFormik } from "formik";
import { FunctionComponent, useState } from "react";
import * as Yup from "yup";
import { useDetectClickOutside } from "react-detect-click-outside";
import { orderBy, uniqBy } from "lodash/fp";

import { SessionUser } from "../../types/session";
import { generateId } from "../../utils/idUtils";
import Button from "../Button";
import ErrorFeedback from "../ErrorFeedback";
import FormTextRow from "../FormTextRow";
import { TabContainer, TabHeader, TabContent } from "../Tab";
import useAppContext from "../../hooks/useAppContext";
import { textFieldBaseStyles } from "../formStyles";
import Popup from "../Popup";
import CheckBoxField from "../CheckBoxField";
import FormRow from "../FormRow";
import UserSelectList from "../UserSelectList";
import Tags from "../UserTags";
import { sortUsers } from "../../utils/sessionUtils";

interface SessionUserFormProps {
  value: Array<SessionUser>;
  onChange: (users: Array<SessionUser>) => void;
  supportSearch?: boolean;
}

const SessionUserSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email"),
  name: Yup.string().required("Required"),
  totalInGroup: Yup.number().positive(),
});

const SessionUserForm: FunctionComponent<SessionUserFormProps> = ({
  value,
  onChange,
  supportSearch,
}) => {
  const [showPopup, setShowPopup] = useState(false);
  const [addToContacts, setAddToContacts] = useState(false);
  const appContext = useAppContext();
  const formik = useFormik<SessionUser>({
    initialValues: {
      id: "",
      email: "",
      name: "",
      isGroup: false,
      totalInGroup: 1,
    },
    validateOnBlur: true,
    validateOnChange: false,
    validationSchema: SessionUserSchema,
    onSubmit: (values) => { },
  });

  const isEditMode = Boolean(formik.values.id);

  const onSaveClicked = () => {
    formik.validateForm();
    if (formik.isValid) {
      if (!formik.values.isGroup) {
        formik.values.totalInGroup = 1;
      }
      if (!isEditMode) {
        formik.values.id = formik.values.email || generateId();
        value.push(formik.values);
      } else {
        const index = value.findIndex((v) => v.id === formik.values.id);
        formik.values.id =
          formik.values.id || formik.values.email || generateId();
        value.splice(index, 1, formik.values);
      }
      if (addToContacts) {
        appContext.addContact(appContext.user!, formik.values);
      }
      onChange(value);
      setShowPopup(false);
      formik.resetForm();
    }
  };

  const onRemoveClicked = (user: SessionUser) => () => {
    onChange(value.filter((v) => v.id !== user.id));
  };

  const toggleFormClicked = () => {
    formik.resetForm();
    setAddToContacts(false);
    setShowPopup(!showPopup);
  };

  const closeForm = () => {
    setShowPopup(false);
  };

  const ref = useDetectClickOutside({ onTriggered: closeForm });

  const onEditClicked = (user: SessionUser) => () => {
    formik.resetForm();
    setAddToContacts(false);
    setShowPopup(true);
    formik.setValues(user);
  };

  const onCheckedChange =
    (field: string): React.ChangeEventHandler<HTMLInputElement> =>
      (e) => {
        formik.setFieldValue(field, e.target.checked);
      };
  const onIsContactChanged: React.ChangeEventHandler<HTMLInputElement> = (
    e
  ) => {
    setAddToContacts(e.target.checked);
  };
  const currentSessionUser: SessionUser = {
    name: appContext.user!.name || "",
    email: appContext.user!.email,
    id: appContext.user!.id,
  };

  const contactList = uniqBy(
    (s) => s.email || s.id,
    ([] as Array<SessionUser>).concat(
      currentSessionUser,
      appContext.user!.contacts || []
    )
  );
  const onUserCheckChanged: React.ChangeEventHandler<HTMLInputElement> = (
    e
  ) => {
    if (!e.target.checked)
      onChange(value.filter((v) => v.id !== e.target.value));
    else {
      const item = contactList.find((u) => u.id === e.target.value);
      value.push(item!);
      onChange(sortUsers(value));
    }
  };
  const onSelectAllClicked = () => {
    onChange(contactList);
  };

  const onClearAllClicked = () => {
    onChange([]);
  };
  const errors = Object.entries(formik.errors);
  return (
    <div ref={ref} className="relative w-full">
      <div className={`${textFieldBaseStyles} flex`}>
        <div className="flex flex-wrap">
          <Tags
            value={value}
            onEditClicked={onEditClicked}
            onRemoveClicked={onRemoveClicked}
            className="pr-6"
          />
        </div>
        {supportSearch && (
          <div className="input flex-1">
            <input className="w-full px-1" placeholder="Type to search" />
          </div>
        )}
        <button
          type="button"
          onClick={toggleFormClicked}
          className="flex-1"
        ></button>

        <button
          type="button"
          onClick={toggleFormClicked}
          className="text-indigo-400 rounded-full border border-indigo-400 absolute right-1"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 4v16m8-8H4"
            />
          </svg>
        </button>
      </div>

      <Popup onBackDropClicked={closeForm} visible={showPopup}>
        <TabContainer
          activeTab={isEditMode ? 1 : 0}
          tabHeader={
            <>
              {!isEditMode && <TabHeader index={0}>From Contacts</TabHeader>}
              <TabHeader index={1}>
                {isEditMode ? "Edit User" : "New User"}
              </TabHeader>
            </>
          }
        >
          {!isEditMode && (
            <TabContent className="shadow-none" index={0}>
              <UserSelectList
                items={contactList}
                value={value}
                onClearAllClicked={onClearAllClicked}
                onSelectAllClicked={onSelectAllClicked}
                onUserCheckChanged={onUserCheckChanged}
                name="sharedBy"
              />
              <div className="flex mt-4 justify-center">
                <Button type="button" onClick={closeForm} className="px-4">Ok</Button>
              </div>
            </TabContent>
          )}
          <TabContent className="shadow-none" index={1}>
            <FormTextRow
              name="name"
              label="Name"
              onChange={formik.handleChange}
              value={formik.values.name}
              textFieldProps={{
                className: "!bg-gray-100"
              }}
            />
            <FormTextRow
              name="email"
              label="Email"
              onChange={formik.handleChange}
              value={formik.values.email}
              textFieldProps={{
                type: "email",
                className: "!bg-gray-100"
              }}
            />
            <FormRow>
              <CheckBoxField
                id="ckb-isGroup"
                onChange={onCheckedChange("isGroup")}
                name="isGroup"
                type="checkbox"
                checked={Boolean(formik.values.isGroup)}
              />
              <label htmlFor="ckb-isGroup" className="ml-2">
                This is a group
              </label>
            </FormRow>
            {formik.values.isGroup && (
              <FormTextRow
                label="Persons in group"
                value={formik.values.totalInGroup || ""}
                onChange={formik.handleChange}
                name="totalInGroup"
                textFieldProps={{
                  type: "number",
                  inputMode: "numeric",
                  className: "!bg-gray-100"
                }}
              />
            )}
            <FormRow>
              <CheckBoxField
                id="ckb-isContact"
                onChange={onIsContactChanged}
                name="isContact"
                type="checkbox"
                checked={addToContacts}
              />
              <label htmlFor="ckb-isContact" className="ml-2">
                Also add to contacts
              </label>
            </FormRow>
            <div className="flex justify-center">
              <Button
                onClick={onSaveClicked}
                type="button"
                className="mt-2 px-4"
              >
                {isEditMode ? "Update" : "Add"}
              </Button>
              <Button
                onClick={toggleFormClicked}
                type="button"
                className="mt-2 ml-2 px-4 bg-red-500"
              >
                Cancel
              </Button>
            </div>
            {Boolean(errors.length) && <ErrorFeedback errors={errors} />}
          </TabContent>
        </TabContainer>
      </Popup>
    </div>
  );
};

export default SessionUserForm;
