import { FunctionComponent, useState } from "react";
import { useDetectClickOutside } from "react-detect-click-outside";

import { SessionUser } from "./types/session";
import { hasCustomSettings, sortUsers } from "./utils/sessionUtils";
import Button from "./components/Button";
import { textFieldBaseStyles } from "./components/formStyles";
import Popup from "./components/Popup";
import { TabContainer, TabContent, TabHeader } from "./components/Tab";
import UserSelectList from "./components/UserSelectList";
import Tags from "./components/UserTags";

interface SessionUserSelectProps {
  value: Array<SessionUser>;
  onChange: (users: Array<SessionUser>) => void;
  items: Array<SessionUser>;
  className?: string;
  tagsClassName?: string;
  name: string;
  showCustomSettings?: boolean;
  readOnly?: boolean;
  isSingleChoice?: boolean;
}

const SessionUserSelect: FunctionComponent<SessionUserSelectProps> = ({
  value,
  onChange,
  items,
  className,
  tagsClassName,
  name,
  showCustomSettings,
  readOnly,
  isSingleChoice,
}) => {
  const [showPopup, setShowPopup] = useState(false);

  const onRemoveClicked = (user: SessionUser) => () => {
    onChange(value.filter((v) => v.id !== user.id));
  };

  const toggleFormClicked = () => {
    setShowPopup(!showPopup);
  };
  const onEditClicked = (user: SessionUser) => () => {
    setShowPopup(true);
  };

  const closeForm = () => {
    setShowPopup(false);
  };

  const onUserCheckChanged: React.ChangeEventHandler<HTMLInputElement> = (
    e
  ) => {
    if (!e.target.checked)
      onChange(value.filter((v) => v.id !== e.target.value));
    else {
      const item = items.find((i) => i.id === e.target.value);
      if (isSingleChoice) {
        onChange([item!]);
      } else {
        value.push(item!);
        onChange(sortUsers(value));
      }
    }
  };

  const ref = useDetectClickOutside({ onTriggered: closeForm });

  const onSelectAllClicked = () => {
    onChange(items);
  };

  const onClearAllClicked = () => {
    onChange([]);
  };

  const onUserUpdated = (user: SessionUser) => {
    const index = value.findIndex((i) => i.id === user.id);
    if (index > -1) {
      value.splice(index, 1, user);
      onChange([...value]);
    }
  }
  const useCustomSettings = showCustomSettings && hasCustomSettings(value, items);
  return (
    <div ref={ref} className={`relative w-full ${className}`}>
      <div className={`${textFieldBaseStyles} ${tagsClassName} flex`}>
        <Tags
          value={value}
          onEditClicked={onEditClicked}
          onRemoveClicked={onRemoveClicked}
          className="pr-6"
          readOnly={readOnly}
        />
        {!readOnly && <button
          type="button"
          onClick={toggleFormClicked}
          className="text-indigo-400 rounded-full border border-indigo-400 absolute right-1"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 4v16m8-8H4"
            />
          </svg>
        </button>}
      </div>
      <Popup visible={showPopup} onBackDropClicked={closeForm}>
        <TabContainer
          activeTab={useCustomSettings ? 1 : 0}
          tabHeader={
            <>
              <TabHeader index={0}>Select users</TabHeader>
              {showCustomSettings && <TabHeader index={1}>Custom settings</TabHeader>}
            </>
          }
        >
          <TabContent className="flex-1 overflow-y-scroll pb-10" index={0}>
            <p className="text-sm italic">Select persons to share</p>
            <UserSelectList
              items={items}
              value={value}
              onClearAllClicked={onClearAllClicked}
              onSelectAllClicked={onSelectAllClicked}
              onUserCheckChanged={onUserCheckChanged}
              name={name}
            />
            <div className="flex mt-4 justify-center">
              <Button type="button" onClick={closeForm} className="px-4">Ok</Button>
            </div>
          </TabContent>
          {showCustomSettings && <TabContent className="flex-1 overflow-y-scroll pb-10" index={1}>
            <p className="text-sm italic">Edit manually number of persons per group</p>
            <UserSelectList
              items={items}
              value={value}
              onClearAllClicked={onClearAllClicked}
              onSelectAllClicked={onSelectAllClicked}
              onUserCheckChanged={onUserCheckChanged}
              onUserChanged={onUserUpdated}
              name={name}
              showEditTotalInGroup
            />
            <div className="flex mt-4 justify-center">
              <Button type="button" onClick={closeForm} className="px-4">Ok</Button>
              <div className="h-10 bg-red-200"></div>
            </div>
          </TabContent>}
        </TabContainer>

      </Popup>
    </div>
  );
};

export default SessionUserSelect;
