import { FunctionComponent, useState } from "react";
import AuthenticatedLayout from "../components/AuthenticatedLayout";
import Button from "../components/Button";
import NewContactForm from "../components/Contacts/NewContactForm";
import Popup from "../components/Popup";
import useAppContext from "../hooks/useAppContext";
import { SessionUser } from "../types/session";
import { TabContainer, TabHeader, TabContent } from "../components/Tab";
import Groups from '../components/Contacts/Groups';

interface ContactsProps { }

const Contacts: FunctionComponent<ContactsProps> = () => {
  const [showForm, setShowForm] = useState(false);
  const [selectedSessionUser, setSelectedSessionUser] = useState<SessionUser>();
  const toggleForm = () => {
    setShowForm(!showForm);
    setSelectedSessionUser(undefined);
  }
  const appContext = useAppContext();
  const onEditClicked = (u: SessionUser) => () => {
    setSelectedSessionUser(u);
    setShowForm(true);
  }

  return (
    <AuthenticatedLayout backUrl="/list" pageTitle="Contacts">
      <div className="flex-1 mt-4 px-4 text-left">

        <TabContainer
          tabHeader={
            <>
              <TabHeader index={0}>Friends</TabHeader>
              <TabHeader index={1}>Groups</TabHeader>
            </>
          }
        >
          <TabContent index={0}>
            <div className="relative">
              <Button onClick={toggleForm} className="mb-2">
                Add new
              </Button>
              <hr />
              <Popup visible={showForm} onBackDropClicked={toggleForm}>
                <NewContactForm
                  key={selectedSessionUser?.id || 'default'}
                  onCancelClicked={toggleForm}
                  onSaveClicked={toggleForm}
                  data={selectedSessionUser}
                />
              </Popup>
            </div>
            {appContext.user!.contacts.map((contact) => (
              <div className="bg-white shadow-md mb-2 px-4" key={contact.id || contact.email}>
                <div className="py-4 flex">
                  <div className="flex-1">
                    <h4 className="font-bold">{contact.name}</h4>
                    <p className="text-sm text-black/[0.7]">{contact.email}</p>
                  </div>
                  <div>
                    <button onClick={onEditClicked(contact)} type="button">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth={2}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
                <hr />
              </div>
            ))}
          </TabContent>
          <TabContent index={1}>
              <Groups />
          </TabContent>
        </TabContainer>
      </div>

    </AuthenticatedLayout>
  );
};

export default Contacts;
