import React, { useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';

const storage = window.sessionStorage;

export const saveScrollPosition = (pathname: string, position: number) => {
  if (storage && storage.setItem) {
    const currKey = `${pathname}_scroll`;
    storage.setItem(currKey, position.toString());
  }
}

const withScrollPosition = <P extends object>(Component: React.FunctionComponent<P>) =>
  (props: P): React.ReactElement => {
    const location = useLocation();

    useLayoutEffect(() => {
      if (storage && storage.getItem) {
        let scrollTo = 0;
        const currKey = `${location.pathname}_scroll`;
        if (storage.getItem(currKey)) {
          scrollTo = +storage.getItem(currKey)!;
        }

        window.scrollTo({ top: scrollTo });
        storage.removeItem(currKey);
      }

      return () => {
      }
    }, [location.pathname])
    return (
      <Component {...props} />
    )
  };

export default withScrollPosition;
