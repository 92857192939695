import { FunctionComponent } from "react";
import { textFieldBaseStyles } from "./formStyles";

export interface TextAreaFieldProps
  extends React.DetailedHTMLProps<
    React.TextareaHTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
  > {}

const TextAreaField: FunctionComponent<TextAreaFieldProps> = ({className, ...props}) => {
  return (
    <textarea {...props} className={`${textFieldBaseStyles} ${className}`} />
  );
};

export default TextAreaField;
