import { Index } from "flexsearch";
import { Session } from "../types/session";
import charMap from './char-map.json';

function convertSpecialCharacters(value: string) {
  // @ts-ignore
  const replaceFromCharMap = (char: string) => charMap[char] || char;
  return value
    .split('')
    .map(replaceFromCharMap)
    .join('');
}

function convertToSlug(value = '', allowedCharacters = '') {
  const successiveDashes = /-{3,}/g;
  const forbiddenCharacters = new RegExp(`[^A-Za-z0-9-_${allowedCharacters}]`, 'g');
  return convertSpecialCharacters(value)
    .replace(successiveDashes, '-')
    .replace(forbiddenCharacters, '')
    .toLowerCase();
}


const index = new Index({
  filter: function (value) {
    return value.length > 2;
  }
});

const data = new Map<string,string>();

const indexString = (input: string) => {
  const id = convertToSlug(input);
  if (!index.contain(id)) {
    data.set(id, input);
    index.append(id, input);
  }
}

export const indexSession = (input: Session) => {
  (input.expenses || []).forEach(expense => {
    indexString(expense.name);
    (expense.items || []).forEach((item) => {
      indexString(item.name);
    })
  })
}

export const search = (query: string, limit?: number) => {
  return index.search(query, limit || 10, { suggest: true });
}

export const getByIndex = (id: string) => {
  return data.get(id);
}