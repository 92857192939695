import { FunctionComponent } from "react";
import Intro from "../components/LandingPage/Intro";
import Concepts from "../components/LandingPage/Concepts";
import Footer from "../components/LandingPage/Footer";
import GuideLines from "../components/LandingPage/GuideLines";

interface LandingPageProps { }

const menu = [
  { label: "Getting started", url: "/getting-started" },
  { label: "App", url: "/" },
];

const LandingPage: FunctionComponent<LandingPageProps> = () => {
  return (
    <div className="min-h-screen bg-gray-100">
      <header className="sticky top-0 bg-gray-100 z-10">
        <nav>
          <ul className="flex text-gray-600 px-4">
            {menu.map((item, index) => (
              <li className={`${index === 0 && 'border border-indigo-500 rounded-full'} my-4`} key={item.url}>
                <a
                  className="px-4 inline-block font-medium"
                  href={item.url}
                >
                  {item.label}
                </a>
              </li>
            ))}
          </ul>
        </nav>
      </header>
      <h1 className="text-2xl relative -bottom-8 inline-block p-4 text-white font-semibold rounded-full bg-gradient-to-r from-sky-500 to-indigo-500 -mt-8">
        SplittaExpens
      </h1>
      <Intro />
      <Concepts />
      <GuideLines />
      <div className="mt-8 lg:mt-16">
        <h2 className="text-4xl font-bold px-4">
          Battled with complex scenarios in real world
        </h2>
        <p className="px-4">
          The app was initially developed to replace awkward excel works every day for calculating expenses
        </p>
      </div>
      <Footer />
    </div>
  );
};

export default LandingPage;
