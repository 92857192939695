import { FunctionComponent } from "react";
import { textFieldBaseStyles } from "./formStyles";

export interface CurrencyFieldProps
  extends React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
  > {
  currency: string;
}

const CurrencyField: FunctionComponent<CurrencyFieldProps> = ({
  className,
  currency,
  ...props
}) => {
  return (
    <div className="w-full">
      <div className="relative w-4/12">
        <input
          {...props}
          type="number"
          className={`${textFieldBaseStyles} ${className} pr-6`}
        />
        <div className="absolute right-2 top-2">{currency}</div>
      </div>
    </div>
  );
};

export default CurrencyField;
