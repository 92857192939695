import { FunctionComponent, useContext, useRef } from "react";
import SessionContext from "../../context/SessionContext";
import useAppContext from "../../hooks/useAppContext";
import SlideButton from "../SlideButton";
import AsyncButton from "./AsyncButton";
import { ReactComponent as ShopingBag } from "../icons/shoping-bag.svg";
import { ReactComponent as ExternalLink } from "../icons/external-link.svg";
import { ReactComponent as Check } from "../icons/check.svg";
import { ReactComponent as Pencil } from "../icons/pencil.svg";

import { Link, useNavigate } from "react-router-dom";

interface RightMenuBottomProps {
  readOnly: boolean;
  hasPermissions?: boolean;
}

const editButtonStyles =
  "font-bold p-2 px-4 border-b border-white flex inline-flex items-center text-left";

const RightMenuBottom: FunctionComponent<RightMenuBottomProps> = ({
  readOnly,
  hasPermissions,
}) => {
  const { session, saveSession, removeSession } = useContext(SessionContext);
  const appContext = useAppContext();
  const ckbRef = useRef<HTMLInputElement>(null);

  const onRemoveClicked = () => {
    if (window.confirm("Are you sure to delete this session?"))
      removeSession(session!);
    ckbRef.current!.checked = !ckbRef.current!.checked;
  };

  const onDoneClicked = () => {
    session!.status = "done";
    saveSession(session!);
    ckbRef.current!.checked = !ckbRef.current!.checked;
  };

  const onReOpenClicked = () => {
    session!.status = "open";
    saveSession(session!);
    ckbRef.current!.checked = !ckbRef.current!.checked;
  };

  const delayHideMenu = () => {
    setTimeout(() => (ckbRef.current!.checked = !ckbRef.current!.checked), 500);
  };

  const onViewLinkClicked = async () => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(
        `${window.location.href.replace("/session/", "/view/")}`
      );
      setTimeout(Promise.resolve, 200);
    }
    delayHideMenu();
  };
  const navigate = useNavigate();
  const onEditClicked = () => {
    navigate(`/session/${session?.id}/edit`);
  };
  return (
    <>
      <SlideButton
        label={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M4 6h16M4 12h8m-8 6h16"
            />
          </svg>
        }
        ckbRef={ckbRef}
        labelClassName="pl-4"
        id="ckb_session_menu"
        popupClassName="text-sm"
      >
        {!readOnly && (
          <div className="flex flex-col text-base">
            {session?.status !== "done" && (
              <button onClick={onDoneClicked} className={editButtonStyles}>
                <Check className="h-6 w-6 inline-block text-green-500" />
                Done
              </button>
            )}
            {session?.status !== "done" && (
              <button onClick={onEditClicked} className={editButtonStyles}>
                <Pencil className="h-6 w-6 inline-block" />
                Edit
              </button>
            )}
            {session?.status !== "done" && (
              <Link
                state={session}
                to={`/session/${session?.id}/shopping-list`}
                className={editButtonStyles}
              >
                <ShopingBag />
                Shopping list
              </Link>
            )}

            {session?.status === "done" && (
              <button onClick={onReOpenClicked} className={editButtonStyles}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M8 11V7a4 4 0 118 0m-4 8v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2z"
                  />
                </svg>
                Reopen
              </button>
            )}
            {
              <button onClick={onRemoveClicked} className={editButtonStyles}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 text-red-500"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
                Remove
              </button>
            }      
            <AsyncButton
              onClick={onViewLinkClicked}
              className={`${editButtonStyles} !border-b-0`}
              text="View Link"
              doneText="Copied view link"
            >
              <ExternalLink />
            </AsyncButton>
          </div>
        )}
        {readOnly && session?.status === "done" && hasPermissions && (
          <button onClick={onReOpenClicked} className={editButtonStyles}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M8 11V7a4 4 0 118 0m-4 8v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2z"
              />
            </svg>
            Reopen
          </button>
        )}
      </SlideButton>
    </>
  );
};

export default RightMenuBottom;
