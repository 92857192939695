import { FunctionComponent } from "react";

interface GuideLinesProps {

}

const GuideLines: FunctionComponent<GuideLinesProps> = () => {
  return (<div className="relative -top-16">
    <h2 className="text-white text-4xl">Getting Started</h2>
    <div className="overflow-x-scroll snap-x scrollbar">
      <div className="mt-16 text-left grid lg:grid-cols-6 gap-4 mx-4 lg:mx-0 guides-scroll">
        <div className="border-2 p-4 rounded-md flex flex-col justify-between">
          <h3 className="font-medium text-xl">1. Login with Google or your email</h3>
          <div className="mt-4 text-center">
            <img className="max-w-full inline-block" src="/login_screen.png" alt="login" />
          </div>
        </div>
        <div className="border-2 p-4 rounded-md flex flex-col justify-between">
          <h3 className="font-medium text-xl">2. Add contacts for adding to sessions later</h3>
          <div className="mt-4 text-center">
            <img className="max-w-full inline-block" src="/list_screen.png" alt="login" />
          </div>
        </div>
        <div className="border-2 p-4 rounded-md flex flex-col justify-between">
          <h3 className="font-medium  text-xl">3. Add a session</h3>
          <div className="mt-4 text-center">
            <img className="max-w-full inline-block" src="/session_screen.png" alt="login" />
          </div>
        </div>
        <div className="border-2 p-4 rounded-md flex flex-col justify-between">
          <h3 className="font-medium  text-xl">4. Add expenses</h3>
          <div className="mt-4 text-center">
            <img className="max-w-full inline-block" src="/expense_screen.png" alt="login" />
          </div>
        </div>
        <div className="border-2 p-4 rounded-md flex flex-col justify-between">
          <h3 className="font-medium  text-xl">5. Double check</h3>
          <div className="mt-4 text-center">
            <img className="max-w-full inline-block" src="/detail_screen.png" alt="login" />
          </div>
        </div>
        <div className="border-2 p-4 rounded-md flex flex-col justify-between">
          <h3 className="font-medium  text-xl">6. View summary</h3>
          <div className="mt-4 text-center">
            <img className="max-w-full inline-block" src="/summary_screen.png" alt="login" />
          </div>
        </div>
      </div>
    </div>
  </div>);
}

export default GuideLines;