import { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import { Session } from "../../types/session";
import { formatFriendlyDateTimeWithoutDay } from "../../utils/dateUtils";
import { sortUsers } from "../../utils/sessionUtils";
import UserTag from "../UserTag";
import { ReactComponent as ChevronRightIcon } from "../icons/chevron-right.svg";

interface ListItemProps {
  item: Session;
  className?: string;
}

const ListItem: FunctionComponent<ListItemProps> = ({ item, className }) => {
  return (
    <li key={item.id} className={`mt-2 p-2 shadow-md bg-white ${className}`}>
      <div className="text-left">
        <p className="text-xs text-black/[0.7]">
          {formatFriendlyDateTimeWithoutDay(item.when)}
        </p>
        <Link to={`/session/${item.id}`} className="font-bold block">
          {item.name}
        </Link>
      </div>
      <div className="info flex justify-between mt-2">
        <div className="text-black/[0.7]">
          {sortUsers(item.sharedBy).map((s) => (
            <UserTag key={s.id} user={s} showAvatar />
          ))}
        </div>
        <Link to={`/session/${item.id}`} className="inline-block">
          <ChevronRightIcon />          
        </Link>
      </div>
    </li>
  );
};

export default ListItem;
