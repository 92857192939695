import React, { useState, useEffect, useRef } from "react";

import AppContext, { Notification } from "../context/AppContext";
import { Group, User } from "../types/user";
import { loadUserInfo } from "../repositories/UserRepository";
import { SessionUser } from "../types/session";
import * as UserRepository from "../repositories/UserRepository";
import * as GroupRepository from "../repositories/GroupRepository";
import * as ConfigRepository from "../repositories/ConfigRepository";
import * as AuthHandler from "../auth/byEmail";
import { currencies } from "../constants/currencies";
import { getAvatar } from "../utils/userUtils";

const withAppContext =
  (Component: React.FunctionComponent) =>
    (props: any): React.ReactElement => {
      const [user, setUser] = useState<User>();
      const [isAuthReady, setIsAuthReady] = useState(false);
      const [isLoading, setIsLoading] = useState(false);
      const [notification, setNotification] = useState<Notification>();
      const [version, setVersion] = useState('');
      const currentVersion = useRef('');
      const [groups, setGroups] = useState<Array<Group>>([]);
      const sendMagicLink = (email: string) => {
        return AuthHandler.sendMagicLink(email);
      };
      const authenticateByMagicLink = (input: string): Promise<User | null> => {
        return AuthHandler.authenticateByMagicLink(input).then(async (result) => {
          if (result) {
            window.localStorage.removeItem("emailForSignIn");
            const user: User = {
              email: result.user.email!,
              password: "******",
              name: result.user.displayName,
              providerId: result.providerId,
              id: result.user.email || result.user.uid,
              contacts: [],
              currency: currencies[0],
            };
            const userInfo = await loadUserInfo(user.id);
            if (userInfo) {
              user.currency = userInfo["currency"];
              user.contacts = userInfo["contacts"] || [];
            }
            return user;
          }
          return null;
        });
      };
      useEffect(() => {
        AuthHandler.onAuthChanged().then(async (user) => {
          if (user) {
            const avt = await getAvatar(user.email!);
            const u: User = {
              email: user.email!,
              password: "******",
              name: user.displayName,
              id: user.email || user.uid,
              contacts: [],
              photoUrl: avt,
            };
            const userInfo = await loadUserInfo(u.id);
            if (userInfo) {
              u.currency = userInfo["currency"];
              u.contacts = userInfo["contacts"] || [];
            }

            setUser(u);
            GroupRepository.loadGroups(u).then(setGroups);
          }
          setIsAuthReady(true);
          ConfigRepository.subscribeConfigs('Prod', (cfg) => {
            setVersion(cfg.version);
            if (!currentVersion.current) {
              currentVersion.current = cfg.version;
            }
          })
        });
      }, []);
      const saveUser = async (u: User) => {
        AuthHandler.saveUser(u).then(() => {
          setUser(u);
        });
      };
      const addContact = async (u: User, contact: SessionUser) => {
        return UserRepository.addContact(u, contact).then(() => {
          setUser(u);
        });
      };

      const saveContact = async (u: User, contact: SessionUser) => {
        return UserRepository.saveContact(u, contact).then(() => {
          setUser(u);
        });
      };

      const removeContact = async (u: User, id: string): Promise<void> => {
        return UserRepository.removeContact(u, id).then(() => {
          setUser(u);
        });
      };
      const showSuccessNotification = (title: string, message: string, onDone?: Function) => {
        setNotification({ title, message, level: "success" });
        if (onDone)
          setTimeout(onDone, 300);
      };
      const showErrorNotification = (title: string, message: string, onDone?: Function) => {
        setNotification({ title, message, level: "error" });
        if (onDone)
          setTimeout(onDone, 300);

      };
      const showInfoNotification = (title: string, message: string, onDone?: Function) => {
        setNotification({ title, message, level: "info" });
        if (onDone)
          setTimeout(onDone, 300);
      };
      const hideNotification = () => {
        setNotification(undefined);
      }
      const logOut = () => {
        return AuthHandler.logOut().then(() => {
          setUser(undefined);
        })
      }
      const loginViaGoogle = () => {
        return AuthHandler.loginViaGoogle().then(async (result) => {
          if (result) {
            const user: User = {
              email: result.user.email!,
              password: "******",
              name: result.user.displayName,
              providerId: result.providerId,
              id: result.user.email || result.user.uid,
              contacts: [],
              currency: currencies[0],
            };
            const userInfo = await loadUserInfo(user.id);
            if (userInfo) {
              user.currency = userInfo["currency"];
              user.contacts = userInfo["contacts"] || [];
            }
            setUser(user);
            return user;
          }
          return null;
        })
      }
      const syncContactsToUser = async (usersToSync?: Array<SessionUser>) => {
        await Promise.all(
          (usersToSync || []).map(async (s) => {
            if (s.email && s.email !== user?.email)
              await addContact(user!, s);
          })
        );
      }
      return (
        <AppContext.Provider
          value={{
            user,
            currentVersion: currentVersion.current,
            latestVersion: version,
            setUser: saveUser,
            sendMagicLink,
            authenticateByMagicLink,
            addContact,
            saveContact,
            removeContact,
            isLoading,
            setIsLoading,
            notification,
            showSuccessNotification,
            showErrorNotification,
            showInfoNotification,
            hideNotification,
            logOut,
            login: setUser,
            loginViaGoogle,
            syncContactsToUser,
            groups,
            setGroups,
          }}
        >
          {!isAuthReady ? (
            <div className="bg-white text-center flex-col flex items-center h-screen justify-center">
              <h1 className="text-2xl"> Welcome to SplittaExpens </h1>
              <p>The app is initializing...</p>
            </div>
          ) : (
            <Component {...props} />
          )}
        </AppContext.Provider>
      );
    };

export default withAppContext;
