import { FunctionComponent } from "react";
import Layout from "../components/Layout";

interface NotFoundProps {

}

const NotFound: FunctionComponent<NotFoundProps> = () => {
  return (<Layout>
    <div className="flex items-center justify-center h-full flex-col">      
      <p className="text-3xl font-semibold">Oops! you are looking for an unreal thing</p>
      <div className="flex mt-8 justify-between w-full px-4 lg:max-w-lg">
        <a href="/">Back to the app</a>
        <div className="hidden lg:block lg:w-32"></div>
        <a href="/getting-started">Learn more about the app</a>
      </div>
    </div>
  </Layout>);
}

export default NotFound;