import React from 'react';
import { Expense, Session, ShoppingItem, ShoppingList } from '../types/session';

export interface ISessionContext {
  session?: Session | null,
  shoppingList?: ShoppingList,
  setSession: React.Dispatch<React.SetStateAction<Session | null>>,
  addExpense: (input: Expense) => Promise<void>,
  saveExpense: (input: Expense) => Promise<void>,
  removeExpense: (input: Expense) => Promise<void>,
  addShoppingItem: (input: ShoppingItem) => Promise<void>,
  saveShoppingItem: (input: ShoppingItem) => Promise<void>,
  saveShoppingItems: (input: Array<ShoppingItem>) => Promise<void>,
  removeShoppingItem: (input: ShoppingItem) => Promise<void>,
  saveSession: (input: Session) => Promise<void>,
  removeSession: (input: Session) => Promise<void>,
}

export default React.createContext<ISessionContext>({
  setSession: () => { },
  addExpense: () => Promise.resolve(),
  saveExpense: () => Promise.resolve(),
  removeExpense: () => Promise.resolve(),
  saveSession: () => Promise.resolve(),
  removeSession: () => Promise.resolve(),
  addShoppingItem: () => Promise.resolve(),
  saveShoppingItems: () => Promise.resolve(),
  saveShoppingItem: () => Promise.resolve(),
  removeShoppingItem: () => Promise.resolve(),
});
