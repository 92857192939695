import { FunctionComponent, useContext } from "react";
import SessionContext from "../../context/SessionContext";
import { getUserKey } from "../../utils/idUtils";

export type ViewSettingsConfig = {
  sharedBy?: string;
  paidBy?: string;
};

interface ViewSettingsProps {
  settings: ViewSettingsConfig;
  onViewSettingsChanged: (field: string, value: string) => void;
  onClosed: () => void;
  onClearClicked: () => void;
}

const ViewSettings: FunctionComponent<ViewSettingsProps> = ({
  settings,
  onViewSettingsChanged,
  onClosed,
  onClearClicked,
}) => {
  const sessionContext = useContext(SessionContext);
  const onSelectChanged =
    (field: string): React.ChangeEventHandler<HTMLSelectElement> =>
      (e) =>
        onViewSettingsChanged(field, e.target.value);

  return (
    <div>
      <h4 className="flex justify-between items-center text-xl mt-4">
        View by:
        <button onClick={onClearClicked} className="text-sm text-indigo-600 leading-4 h-4">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-4 w-4 inline-block"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
          Clear filter
        </button>
      </h4>
      <ul className="mt-4">
        <li className="flex items-center">
          <span className="font-bold text-indigo-500 w-3/12">Paid by: </span>
          <select
            onChange={onSelectChanged("paidBy")}
            value={settings.paidBy}
          >
            <option value="">All</option>
            {sessionContext.session!.sharedBy.map((u) => (
              <option key={getUserKey(u)} value={getUserKey(u)}>{u.name}</option>
            ))}
          </select>
        </li>
        <li className="flex mt-2 items-center">
          <span className="font-bold text-indigo-500 w-3/12">Shared by: </span>
          <select
            onChange={onSelectChanged("sharedBy")}
            value={settings.sharedBy}
          >
            <option value="">All</option>
            {sessionContext.session!.sharedBy.map((u) => (
              <option key={getUserKey(u)} value={getUserKey(u)}>{u.name}</option>
            ))}
          </select>
        </li>
      </ul>
    </div>
  );
};

export default ViewSettings;
