import { FunctionComponent } from "react";

interface InfoRowProps {
  label: string;
  value?: string | React.ReactNode;
  className?: string;
}

const InfoRow: FunctionComponent<InfoRowProps> = ({ label, value, className }) => {
  return (
    <div className="px-4 py-2">
      <label className="text-xs text-black/[0.7]">{label}</label>
      <div className={className}>{value}&nbsp;</div>
    </div>
  );
};

export default InfoRow;
