import { FunctionComponent, useContext, useEffect, useState } from "react";
import TabContext from "../context/TabContext";

interface TabProps {}

interface TabHeaderProps {
  children: React.ReactNode;
  index: number;
}

export const TabHeader: FunctionComponent<TabHeaderProps> = ({
  children,
  index,
}) => {
  const tabContext = useContext(TabContext);
  const onTabClicked = () => tabContext.changeTab(index);
  return (
    <button
      type="button"
      onClick={onTabClicked}
      className={`py-2 uppercase border-b-4 text-left mr-4 ${
        index === tabContext.current
          ? "font-bold border-indigo-600"
          : "border-transparent text-gray-600"
      }
      `}
    >
      {children}
    </button>
  );
};

interface TabContentProps {
  children: React.ReactNode;
  index: number;
  className?: string;
}

export const TabContent: FunctionComponent<TabContentProps> = ({
  children,
  index,
  className,
}) => {
  const tabContext = useContext(TabContext);
  return tabContext.current === index ? (
    <div className={`${className}`}>{children}</div>
  ) : null;
};

interface TabContainerProps {
  children: React.ReactNode;
  tabHeader: React.ReactNode;
  activeTab?: number;
}

export const TabContainer: FunctionComponent<TabContainerProps> = ({
  children,
  tabHeader,
  activeTab,
}) => {
  const [current, changeTab] = useState(activeTab || 0);
  useEffect(() => {
    if (activeTab !== undefined) {
      changeTab(activeTab);
    }
  }, [activeTab]);
  return (
    <TabContext.Provider value={{ current, changeTab }}>
      <div className="flex pb-2">{tabHeader}</div>
      {children}
    </TabContext.Provider>
  );
};

const Tab: FunctionComponent<TabProps> = () => {
  return (
    <TabContainer
      tabHeader={
        <>
          <TabHeader index={0}>Tab 1</TabHeader>
          <TabHeader index={1}>Tab 2</TabHeader>
        </>
      }
    >
      <TabContent className="flex-1" index={0}>Tab Content 1</TabContent>
      <TabContent className="flex-1" index={1}>Tab Content 2</TabContent>
    </TabContainer>
  );
};

export default Tab;
