import { FunctionComponent, useState } from "react";
import useAppContext from "../../hooks/useAppContext";
import { Group, User } from "../../types/user";
import { sortUsers } from "../../utils/sessionUtils";
import Button from "../Button";
import Popup from "../Popup";
import UserSpan from "../Session/UserSpan";
import GroupForm from "./GroupForm";
import * as GroupRepository from '../../repositories/GroupRepository';

interface GroupsProps { }

const doIHavePermission = (group: Group, user: User) => group.createdBy === user.email;

const Groups: FunctionComponent<GroupsProps> = () => {
  const [showForm, setShowForm] = useState(false);
  const appContext = useAppContext();
  const [selectedGroup, setSelectedGroup] = useState<Group>();
  const toggleForm = () => {
    setShowForm(!showForm);
    setSelectedGroup(undefined);
  };
  const saveGroup = (group: Group) => {
    return GroupRepository.saveGroup(group).then(async () => {
      const index = appContext.groups.findIndex(g => g.id === group.id);
      if (index === -1) {
        appContext.groups.push(group);
        appContext.setGroups(appContext.groups);
      } else {
        appContext.groups.splice(index, 1, group);
        appContext.setGroups(appContext.groups);
      }
      appContext.showSuccessNotification(
        "Successfully saved!",
        "Group is saved",
        toggleForm
      );
    });
  }

  const onEditClicked = (group: Group) => () => {
    setShowForm(!showForm);
    setSelectedGroup(group);
  }

  const removeGroup = async () => {
    if (!selectedGroup) return;
    return GroupRepository.removeGroup(selectedGroup.id).then(async () => {
      const index = appContext.groups.findIndex(g => g.id === selectedGroup.id);
      if (index !== -1) {

        appContext.groups.splice(index, 1);
        appContext.setGroups(appContext.groups);
      }
      appContext.showSuccessNotification(
        "Successfully saved!",
        "Group is removed",
        toggleForm
      );
    });
  }

  return (
    <div>
      <div className="relative">
        <Button onClick={toggleForm} className="mb-2">
          New group
        </Button>
        <hr />
        <Popup visible={showForm} onBackDropClicked={toggleForm}>
          <GroupForm
            key={selectedGroup?.id || "default"}
            onCancelClicked={toggleForm}
            onSaveClicked={saveGroup}
            onRemoveClicked={removeGroup}
            data={selectedGroup}
          />
        </Popup>
      </div>
      {appContext.groups.map((g) => (
        <div
          className="bg-white shadow-md mb-2 px-4"
          key={g.id}
        >
          <div className="py-4 flex">
            <div className="flex-1">
              <h4 className="font-bold">{g.name}</h4>
              {sortUsers(g.users).map((u) => (
                <UserSpan user={u} key={u.id}></UserSpan>
              ))}
            </div>
            <div>
              {doIHavePermission(g, appContext.user!) && <button onClick={onEditClicked(g)} type="button">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                  />
                </svg>
              </button>}
            </div>
          </div>
          <hr />
        </div>
      ))}
    </div>
  );
};

export default Groups;
