import { FunctionComponent } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";

import AuthenticatedLayout from "../components/AuthenticatedLayout";
import FormTextRow from "../components/FormTextRow";
import FormSelectRow from "../components/FormSelectRow";
import Button from "../components/Button";
import useAppContext from "../hooks/useAppContext";
import { currencyOpts } from "../constants/currencies";
import ErrorFeedback from "../components/ErrorFeedback";
import { useNavigate } from "react-router-dom";
import Notification from "../components/Notification";
import { getShortName } from "../utils/idUtils";
import { getColorForUser } from "../utils/colorUtils";
import * as UserRepository from "../repositories/UserRepository";
import { setAvatar } from "../utils/userUtils";

interface SettingsProps {}

const UserSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Required"),
  name: Yup.string().required("Required"),
});

const Settings: FunctionComponent<SettingsProps> = () => {
  const navigate = useNavigate();
  const appContext = useAppContext();
  const formik = useFormik({
    initialValues: appContext.user!,
    validateOnBlur: true,
    validateOnChange: false,
    validationSchema: UserSchema,
    onSubmit: (values) => {
      appContext.hideNotification();
      formik.validateForm();
      if (formik.isValid) {
        appContext.setIsLoading(true);
        appContext
          .setUser(values)
          .then(() => {
            appContext.showSuccessNotification(
              "Successfully saved!",
              "Your settings is up-to-date",
              () => {
                if (!appContext.user?.name) {
                  navigate("/list");
                }
              }
            );
          })
          .catch((err) => {
            appContext.showErrorNotification(
              "Unsuccessfully saved!",
              "Your settings is not saved"
            );
          })
          .finally(() => {
            appContext.setIsLoading(false);
          });
      }
    },
  });
  const errors = Object.entries(formik.errors);
  const onLogOutClicked = () => {
    appContext.logOut();
  };
  const onFileUploadChange: React.ChangeEventHandler<HTMLInputElement> = (
    e
  ) => {
    if (e.target.files && e.target.files[0])
      UserRepository.uploadAvatar(appContext.user!.id, e.target.files[0]).then(
        (res) => {
          formik.setFieldValue("photoUrl", res);
          setAvatar(appContext.user?.email!, res)
        }
      );
  };

  return (
    <AuthenticatedLayout backUrl="/list" pageTitle="Settings" renderRightButton={() => <span></span>}>
      <div className="flex flex-row-reverse px-4 mt-2">
        <button onClick={onLogOutClicked} className="text-indigo-500 underline">
          Logout
        </button>
      </div>
      <div className="text-left mt-4">
        <form onSubmit={formik.handleSubmit}>
          <div className="text-center">
            <div className={`mb-4 overflow-hidden inline-block`}>
              <div
                className={`w-16 h-16 text-white font-bold flex inline-flex items-center justify-center rounded-full font-bold text-white overflow-hidden ${getColorForUser(
                  appContext.user!
                )}`}
              >
                {!formik.values.photoUrl ? (
                  getShortName(appContext.user?.name || "")
                ) : (
                  <img
                    alt={appContext.user?.name!}
                    src={ formik.values.photoUrl!}
                  />
                )}
              </div>
              <label
                htmlFor="input_avatar"
                className="text-black block border rounded-full px-4 mt-2 border-gray-600 text-gray-600 text-sm"
              >
                Change
              </label>
              <input
                onChange={onFileUploadChange}
                type="file"
                className="hidden"
                id="input_avatar"
              />
            </div>
          </div>
          <FormTextRow
            label="Name"
            name="name"
            onChange={formik.handleChange}
            value={formik.values.name || ""}
            className="border-t"
            textFieldProps={{
              disabled: appContext.isLoading,
            }}
          />
          <FormTextRow
            label="Email"
            name="email"
            onChange={formik.handleChange}
            textFieldProps={{
              onChange: formik.handleChange,
              name: "email",
              disabled: appContext.isLoading,
              type: "email",
            }}
            value={formik.values.email}
          />
          <FormSelectRow
            label="Currency"
            name="currency"
            onChange={formik.handleChange}
            selectFieldProps={{
              items: currencyOpts,
              disabled: appContext.isLoading,
            }}
            value={formik.values.currency}
          />
          <div className="flex justify-center mt-4">
            <Button
              disabled={appContext.isLoading}
              loading={appContext.isLoading}
              type="submit"
              className="px-4"
            >
              Save
            </Button>
          </div>
          {Boolean(errors.length) && <ErrorFeedback errors={errors} />}
          <Notification />
        </form>
      </div>
    </AuthenticatedLayout>
  );
};

export default Settings;
