import { FunctionComponent, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Expense, Session } from "../types/session";
import * as SessionRepository from '../repositories/SessionRepository';
import SessionContext from "../context/SessionContext";
import SessionInfo from "../components/Session/SessionInfo";
import { TabContainer, TabContent, TabHeader } from "../components/Tab";
import React from "react";
import ExpenseRow from "../components/Session/ExpenseRow";
import Summary from "../components/Session/Summary";
import dayjs from "dayjs";
import { get, groupBy, orderBy } from "lodash/fp";
import { formatFriendlyDateTimeWithoutDay } from "../utils/dateUtils";
import { sumExpenses } from "../utils/sessionUtils";

interface SessionViewOnlyProps {

}

const SessionViewOnly: FunctionComponent<SessionViewOnlyProps> = () => {
  const params = useParams<{ id: string }>();
  const [session, setSession] = useState<Session | null>(null);
  useEffect(() => {
    if (params.id) {
      SessionRepository.loadSession(params.id).then(setSession)
    }
  }, [params.id]);
  const defaultActions = {
    addExpense: () => Promise.resolve(),
    saveExpense: () => Promise.resolve(),
    removeExpense: () => Promise.resolve(),
    saveSession: () => Promise.resolve(),
    removeSession: () => Promise.resolve(), 
    addShoppingItem: () => Promise.resolve(),
    removeShoppingItem: () => Promise.resolve(),
    saveShoppingItem: () => Promise.resolve(),
    saveShoppingItems: () => Promise.resolve(),
  }
  const groupedExpenses = orderBy(
    ([g]) => new Date(g),
    "desc",
    Object.entries(
      groupBy(
        (s: Expense) =>
          s.when
            ? formatFriendlyDateTimeWithoutDay(s.when)
            : formatFriendlyDateTimeWithoutDay(new Date().toISOString()),
        session?.expenses || []
      )
    )
  );
  return (<SessionContext.Provider
    value={{
      session,
      setSession,
      ...defaultActions,
    }}
  >
    {session ? <div className="overflow-auto h-full pb-10 bg-slate-100 min-h-screen">
      <SessionInfo readOnly />
      <div className="text-left mt-4 px-4 bg-white py-4 pb-8">
        <TabContainer
          activeTab={1}
          tabHeader={
            <>
              <TabHeader index={0}>Detail</TabHeader>
              <TabHeader index={1}>Summary</TabHeader>
            </>
          }
        >
          <TabContent index={0}>
            {groupedExpenses.map(([key, values], index) => (
              <div key={key || index}>
                <h5 className="font-bold mt-2 py-1 justify-between flex">
                  {dayjs(key).format("dddd, YYYY-MM-DD")}
                  <span>
                    Total: {sumExpenses(values)} {get("[0].currency", values)}
                  </span>
                </h5>
                {values.map((expense) => (
                  <React.Fragment key={expense.id}>
                    <hr />
                    <ExpenseRow data={expense} readOnly />
                  </React.Fragment>
                ))}
              </div>
            ))}
          </TabContent>
          <TabContent index={1}>
            <Summary />
          </TabContent>
        </TabContainer>
      </div>
    </div> : 'Loading...'}
  </SessionContext.Provider>);
}

export default SessionViewOnly;