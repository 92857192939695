import { FunctionComponent } from "react";
import { Link } from "react-router-dom";

interface BottomButtonProps {
  linkText: string;
  link: string;
}

const BottomButton: FunctionComponent<BottomButtonProps> = ({ linkText, link }) => {
  return (
    <>
      <Link
        className="rounded-full inline-block bg-gradient-to-r from-indigo-500 to-pink-500 p-2 text-white my-1 mr-1 ml-2"
        to={link}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M12 4v16m8-8H4"
          />
        </svg>
      </Link>
      <Link to={link} className="font-bold px-2 relative">
        {linkText}
      </Link>
    </>
  );
};

export default BottomButton;
